export const RESULTS_LIMIT = [
  {
    value: "100",
  },
  {
    value: "200",
  },
  {
    value: "500",
  },
  {
    value: "1000",
  },
];

export const SORTING_OPTIONS = [
  {
    value: "NATURAL",
    label: "Normal",
  },
  {
    value: "ID_ASC",
    label: "ID : Smallest to largest",
  },
  {
    value: "ID_DESC",
    label: "ID : Largest to smallest",
  },
  {
    value: "ORDER_NUMBER_ASC",
    label: "Order Number : Smallest to largest",
  },
  {
    value: "ORDER_NUMBER_DESC",
    label: "Order Number : Largest to smallest",
  },
  {
    value: "APARTMENT_ASC",
    label: "Apartment: A to Z",
  },
  {
    value: "APARTMENT_DESC",
    label: "Apartment: Z to A",
  },
  {
    value: "CREATED_AT_DESC",
    label: "Created: Most recent to oldest",
  },
  {
    value: "CREATED_AT_ASC",
    label: "Created: Oldest to most recent",
  },
];

export const ORDER_NUMBERS = [
  {
    value: "",
    label: "Show all",
  },
  { label: "555", value: "555" },
  { label: "618", value: "618" },
  { label: "619", value: "619" },
  { label: "621", value: "621" },
  { label: "622", value: "622" },
  { label: "623", value: "623" },
  { label: "624", value: "624" },
  { label: "625", value: "625" },
  { label: "626", value: "626" },
];

export const DEFAULT_ORDER_NUMBERS = [
  "555",
  "618",
  "619",
  "621",
  "622",
  "623",
  "624",
  "625",
  "626",
];
