import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestApi } from "../../utilities";
import { GET_VIOLATIONS, GET_VIOLATION } from "../../queries";

const initialState = {
  fetching: false,
  totalCount: 0,
  violationsById: {},
};

export const fetchViolations = createAsyncThunk(
  "violations/fetchViolations",
  async (variables) => {
    try {
      const { data } = await requestApi(GET_VIOLATIONS, variables);

      if (data.violations.nodes === null) {
        return null;
      }

      return {
        violations: data.violations.nodes,
        totalCount: data.violations.totalCount,
        pageInfo: data.violations.pageInfo,
      };
    } catch {
      return null;
    }
  }
);

export const fetchViolation = createAsyncThunk(
  "violations/fetchViolation",
  async (id) => {
    try {
      const { data } = await requestApi(GET_VIOLATION, { id: parseInt(id) });

      return {
        id,
        violation: data.violation,
      };
    } catch {
      return {
        id,
        violation: null,
      };
    }
  }
);

const { reducer } = createSlice({
  name: "violations",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchViolations.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(fetchViolations.fulfilled, (state, action) => {
      state.violations = action.payload?.violations || null;
      state.totalCount = action.payload?.totalCount ?? 0;
      state.pageInfo = action.payload?.pageInfo || null;
      state.fetching = false;
    });

    builder.addCase(fetchViolation.fulfilled, (state, action) => {
      state.violationsById[action.payload.id] = action.payload.violation;
    });
  },
});

export default reducer;
