import "./Toggle.scss";
import React, { useState } from "react";
import Label from "../Label/Label";
import PropTypes from "prop-types";

const Toggle = ({ name, label, onChange }) => {
  const [on, updateOn] = useState(false);

  const onClick = () => {
    onChange({
      target: {
        name,
        value: !on,
      },
    });
    updateOn(!on);
  };

  return (
    <div className="toggle" onClick={onClick} name={name}>
      <Label label={label} />
      <div className={`toggle-inner ${on ? "on" : "off"}`}>
        <div className="circle" />
        <div className="status">{on ? "On" : "Off"}</div>
      </div>
    </div>
  );
};

Toggle.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default Toggle;
