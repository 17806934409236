import "./Top.scss";
import React from "react";

const Top = () => {
  return (
    <div className="top">
      <h1>HPD</h1>
      <h2>Administration panel</h2>
    </div>
  );
};

export default Top;
