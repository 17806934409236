import React, { useState, useMemo } from "react";
import { Select, LoadingSpinner } from "../../components";
import { useAllPremisesStore } from "../../store/premises/hooks";

export const SelectPremise = ({ onSelectPremise }) => {
  const [selectedPremise, setSelectedPremise] = useState(null);

  const { fetching, premises } = useAllPremisesStore();

  const normalizedPremises = useMemo(() => {
    if (!premises || !premises?.length) {
      return [];
    }

    return premises.map(({ id: value, address, city, state, zip }) => {
      const label = `${address}, ${city} ${state} ${zip}`;
      return {
        value,
        label,
      };
    });
  }, [premises]);

  const onSelect = (event) => {
    setSelectedPremise(event.target.value);

    if (typeof onSelectPremise === "function") {
      onSelectPremise(
        premises.find((premise) => premise.id === event.target.value)
      );
    }
  };

  if (fetching) {
    return (
      <div className="select-clients">
        <LoadingSpinner label="Fetching premises.." />
      </div>
    );
  }

  if (premises?.length === 0) {
    return null;
  }

  return (
    <div className="field">
      <Select
        label="Select premise"
        name="premise"
        options={normalizedPremises}
        onChange={onSelect}
        value={selectedPremise}
        className="inline-select"
      />
    </div>
  );
};
