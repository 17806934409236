import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestApi } from "../../utilities";
import { GET_JOBS } from "../../queries";

const initialState = {
  fetching: false,
};

export const fetchJobs = createAsyncThunk("jobs/fetchJobsStatus", async () => {
  try {
    const { data } = await requestApi(GET_JOBS);

    if (data.jobs.nodes === null) {
      return null;
    }

    return data.jobs.nodes;
  } catch {
    return null;
  }
});

const { reducer } = createSlice({
  name: "jobs",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchJobs.pending, (state, action) => {
      state.fetching = true;
    });

    builder.addCase(fetchJobs.fulfilled, (state, action) => {
      state.jobs = action.payload;
      state.fetching = false;
    });
  },
});

export default reducer;
