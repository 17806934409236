import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBuildings } from "./reducer";

export const useBuildingsStore = (variables = {}) => {
  const dispatch = useDispatch();
  const fetching = useSelector((state) => state.buildings.fetching);
  const buildings = useSelector((state) => state.buildings.buildings);
  const totalCount = useSelector((state) => state.buildings.totalCount);
  const pageInfo = useSelector((state) => state.buildings.pageInfo);

  useEffect(() => {
    dispatch(fetchBuildings(variables));
  }, [dispatch, variables]);

  return {
    fetching,
    buildings,
    totalCount,
    pageInfo,
  };
};
