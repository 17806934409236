import React, { useState, useEffect } from "react";
import { LoadingSpinner, Icon, Page, Select, Table } from "../../components";
import { useBuildingsStore } from "../../store/buildings/hooks";
import { BOROUGH_FILTERS, SORTING_OPTIONS, RESULTS_LIMIT } from "./constants";
import { useHistory } from "react-router-dom";
import { parseQueryString } from "../../utilities";
import { useSelector } from "react-redux";
import qs from "qs";

const Buildings = () => {
  const history = useHistory();
  const search = useSelector((state) => state.search.string);

  const [variables, updateVariables] = useState(
    parseQueryString(history.location?.search, {
      first: 100,
      offset: 0,
      orderBy: "NATURAL",
    })
  );
  const { fetching, totalCount, buildings, pageInfo } =
    useBuildingsStore(variables);

  useEffect(() => {
    const vars = { ...variables };
    if (!search) {
      if (vars?.filter?.search?.matches) {
        vars.offset = 0;
        delete vars.filter;
        updateVariables(vars);
      }
      return;
    } else if (vars?.filter?.search?.matches !== search) {
      vars.filter = {
        ...vars.filter,
        search: {
          matches: search,
        },
      };
      vars.offset = 0;
      updateVariables(vars);
    }
  }, [search, variables]);

  if (buildings === undefined) {
    return (
      <div style={{ margin: "1rem" }}>
        <LoadingSpinner label="Loading buildings" />
      </div>
    );
  }

  const onFilterChange = (event) => {
    const { name, value } = event.target;
    const vars = { ...variables, offset: 0 };

    if (name === "sortBy") {
      vars.orderBy = value;
    }

    if (name === "resultsPerPage") {
      vars.first = parseInt(value);
    }

    if (name === "filterByBorough") {
      if (value === "") {
        delete vars.condition;
      } else {
        vars.condition = {
          borough: value,
        };
      }
    }

    history.push({
      pathname: "/buildings",
      search: qs.stringify(vars),
    });

    updateVariables(vars);
  };

  const onPreviousPage = () => {
    if (!pageInfo.hasPreviousPage || fetching) {
      return;
    }

    updateVariables({
      ...variables,
      offset: variables.offset - variables.first,
    });
  };

  const onNextPage = () => {
    if (!pageInfo.hasNextPage || fetching) {
      return;
    }

    updateVariables({
      ...variables,
      offset: variables.offset + variables.first,
    });
  };

  const filters = [
    <Select
      key="sortBy"
      name="sortBy"
      label="Sort by"
      options={SORTING_OPTIONS}
      onChange={onFilterChange}
      value={variables.orderBy}
    />,
    <Select
      key="resultsPerPage"
      name="resultsPerPage"
      label="Results per page"
      options={RESULTS_LIMIT}
      onChange={onFilterChange}
      value={variables.first.toString()}
    />,
    <Select
      key="filterByBorough"
      name="filterByBorough"
      label="Filter by borough"
      options={BOROUGH_FILTERS}
      onChange={onFilterChange}
      value={variables.condition?.borough || ""}
    />,
  ];

  const pageProps = {
    totalCount,
    filters,
    onPreviousPage,
    onNextPage,
  };

  const totalPageCount = Math.round(totalCount / variables.first);
  const currentPage = variables.offset / variables.first + 1;

  return (
    <Page
      {...pageProps}
      currentPage={currentPage}
      totalPageCount={totalPageCount}
      loading={fetching}
    >
      <Table
        headers={[
          "ID",
          "House Number",
          "Street Name",
          "Post code",
          "Borough",
          "Legal Stories",
          "Actions",
        ]}
      >
        {buildings.map((building, idx) => {
          const { id, houseNumber, streetName, postCode, borough, stories } =
            building;
          const destinationQuery = `${houseNumber} ${streetName} ${borough} ${
            postCode ?? ""
          }`
            .replaceAll(" ", "+")
            .trim();

          return (
            <Table.Row key={idx}>
              <Table.RowData>{id}</Table.RowData>
              <Table.RowData>{houseNumber}</Table.RowData>
              <Table.RowData>{streetName}</Table.RowData>
              <Table.RowData>{postCode || "N/A"}</Table.RowData>
              <Table.RowData>{borough}</Table.RowData>
              <Table.RowData>{stories}</Table.RowData>
              <Table.RowData>
                <a
                  href={`https://www.google.com/maps?saddr=My+Location&daddr=${destinationQuery}`}
                  target="_blank"
                  rel="noreferrer"
                  className="directions"
                >
                  <Icon name="directions" />
                  Get directions
                </a>{" "}
              </Table.RowData>
            </Table.Row>
          );
        })}
      </Table>
    </Page>
  );
};

export default Buildings;
