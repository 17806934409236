import "./UpdatePremise.scss";
import React, { useState } from "react";
import { Button, Icon, Modal } from "../components";
import { useDispatch } from "react-redux";
import { updatePremise } from "../store/premises/reducer";
import {
  CORPORATION_FIELDS,
  MANAGER_FIELDS,
  PREMISE_FIELDS,
} from "./CreatePremise";

const UpdatePremise = ({ premise, onClose }) => {
  const dispatch = useDispatch();

  const [state, updateState] = useState(premise);

  const onChange = (event) => {
    updateState((currState) => ({
      ...currState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = async () => {
    if (Object.keys(state).some((key) => state[key] === "")) {
      return;
    }

    const response = await dispatch(updatePremise(state));

    if (!response.payload) {
      alert("Something went wrong");
      return;
    }

    onClose();
  };

  return (
    <Modal
      title="Edit premise"
      onClose={onClose}
      footer={
        <Button onClick={onSubmit}>
          <Icon name="save" />
          Save
        </Button>
      }
    >
      <div className="update-premise">
        <div className="update-premise-fields-group">
          <h1>Corporation</h1>
          {CORPORATION_FIELDS.map((currField, idx) => {
            const [name, type, placeholder] = currField;
            return (
              <div key={idx} className="field">
                <label htmlFor={name}>{placeholder}</label>
                <input
                  value={state[name]}
                  name={name}
                  type={type}
                  onChange={onChange}
                />
              </div>
            );
          })}
        </div>
        <div className="update-premise-fields-group">
          <h1>Manager</h1>
          {MANAGER_FIELDS.map((currField, idx) => {
            const [name, type, placeholder] = currField;
            return (
              <div key={idx} className="field">
                <label htmlFor={name}>{placeholder}</label>
                <input
                  value={state[name]}
                  name={name}
                  type={type}
                  onChange={onChange}
                />
              </div>
            );
          })}
        </div>
        <div className="update-premise-fields-group">
          <h1>Premise</h1>
          {PREMISE_FIELDS.map((currField, idx) => {
            const [name, type, placeholder] = currField;
            return (
              <div key={idx} className="field">
                <label htmlFor={name}>{placeholder}</label>
                <input
                  value={state[name]}
                  name={name}
                  type={type}
                  onChange={onChange}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default UpdatePremise;
