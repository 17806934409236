import "./Templates.scss";

import React from "react";
import {
  useTemplatesStore,
  useReplaceTemplate,
} from "../../store/templates/hooks";
import { LoadingSpinner, Page, Table, Icon } from "../../components";
import { formatDistance } from "date-fns";
import filesize from "filesize";
import { REST_ENDPOINT } from "../../constants";

const Templates = () => {
  const { templates, totalCount } = useTemplatesStore();
  const { uploading, onReplaceTemplate } = useReplaceTemplate();

  if (templates === undefined) {
    return (
      <div style={{ margin: "1rem" }}>
        <LoadingSpinner label="Loading buildings" />
      </div>
    );
  }

  const onReplace = (event) => {
    const [file] = event.target.files;
    const filename = event.target.id;

    onReplaceTemplate(file, filename);
  };

  return (
    <div className="templates">
      <Page
        totalCount={totalCount}
        currentPage={1}
        totalPageCount={0}
        loading={uploading}
        loadingLabel="Uploading file.."
      >
        <Table headers={["Name", "Last Modified", "Size", "Actions"]}>
          {templates?.map((template, idx) => {
            const { name, filename, modifiedAt, size } = template;
            const downloadLink = REST_ENDPOINT + "/documents/" + filename;

            return (
              <Table.Row key={idx}>
                <Table.RowData>{name}</Table.RowData>
                <Table.RowData>
                  {formatDistance(new Date(modifiedAt), new Date(), {
                    addSuffix: true,
                  })}
                </Table.RowData>
                <Table.RowData>
                  {filesize(size, { standard: "jedec" })}
                </Table.RowData>
                <Table.RowData>
                  <div className="template-actions">
                    <div className="replace-file">
                      <Icon name="upload" />
                      <input
                        type="file"
                        id={filename}
                        onChange={onReplace}
                        hidden
                      />
                      <label htmlFor={filename}>Replace</label>
                    </div>
                    <div className="template-download">
                      <Icon name="download" />
                      <a href={downloadLink} download={filename}>
                        Download
                      </a>
                    </div>
                  </div>
                </Table.RowData>
              </Table.Row>
            );
          })}
        </Table>
      </Page>
    </div>
  );
};

export default Templates;
