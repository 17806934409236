import React, { useState } from "react";
import { requestApi } from "../../utilities";
import { GET_CONTACTS_CSV, UPDATE_EXPORTED_COUNT } from "../../queries";
import { useDispatch } from "react-redux";
import { setExportedCount } from "../../store/contacts/reducer";
import { REST_ENDPOINT } from "../../constants";

const Export = ({ variables, type, label, totalCount }) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);

  const onClick = async () => {
    setFetching(true);

    const { csvFile, ids } = await requestApi(
      GET_CONTACTS_CSV,
      type === "current"
        ? variables
        : {
            ...variables,
            first: totalCount,
            offset: 0,
          },
      {
        "x-export-csv": "contacts",
      }
    );

    window.open(REST_ENDPOINT + "/files/" + csvFile, "_blank").focus();

    setFetching(false);

    if (type === "current") {
      dispatch(setExportedCount({ ids }));
    }

    await requestApi(UPDATE_EXPORTED_COUNT, {
      ids,
    });
  };

  return (
    <button className="csv-button" onClick={onClick}>
      {fetching ? "Please wait.." : label}
    </button>
  );
};

export default Export;
