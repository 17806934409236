import "./Clients.scss";
import React, { useState } from "react";
import {
  LoadingSpinner,
  Page,
  Table,
  Button,
  Icon,
  Field,
} from "../../components";
import { useClientsStore } from "../../store/clients/hooks";
import { CreateClient } from "../../modals";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

const Clients = () => {
  const [showCreateClient, toggleShowCreateClient] = useState(false);

  const [variables, updateVariables] = useState({
    first: 100,
    offset: 0,
  });

  const { fetching, totalCount, clients, pageInfo } =
    useClientsStore(variables);

  if (clients === undefined) {
    return (
      <div style={{ margin: "1rem" }}>
        <LoadingSpinner label="Loading.." />
      </div>
    );
  }

  const onPreviousPage = () => {
    if (!pageInfo.hasPreviousPage || fetching) {
      return;
    }

    updateVariables({
      ...variables,
      offset: variables.offset - variables.first,
    });
  };

  const onNextPage = () => {
    if (!pageInfo.hasNextPage || fetching) {
      return;
    }

    updateVariables({
      ...variables,
      offset: variables.offset + variables.first,
    });
  };

  const onSearch = debounce((event) => {
    const value = event.target.value;

    updateVariables((currentVariables) => {
      const variables = {
        ...currentVariables,
      };

      if (value === "") {
        delete variables.filter;
      } else {
        variables.filter = {
          premisesByClientId: {
            some: { search: { matches: value } },
          },
        };
      }

      return variables;
    });
  }, 250);

  const totalPageCount = Math.round(totalCount / variables.first);
  const currentPage = variables.offset / variables.first + 1;

  return (
    <>
      <Page
        totalCount={totalCount}
        currentPage={currentPage}
        totalPageCount={totalPageCount}
        loading={fetching}
        filters={[
          <Field
            key="filter"
            placeholder="Search by address"
            onChange={onSearch}
          />,
          <Button
            key="create-client"
            onClick={() => toggleShowCreateClient(true)}
          >
            <Icon name="plus" />
            Create client
          </Button>,
        ]}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      >
        <Table
          headers={[
            "ID",
            "Name",
            "Phone",
            "Email",
            "Borough",
            "# of Properties",
            "# of Units",
          ]}
        >
          {clients?.map((client, idx) => {
            const {
              id,
              name,
              phoneNumber,
              email,
              city,
              propertyCount,
              unitCount,
            } = client;

            return (
              <Table.Row key={idx}>
                <Table.RowData>
                  <Link to={`/client/${id}`} className="link" target="_blank">
                    {id}
                  </Link>
                </Table.RowData>
                <Table.RowData>
                  <Link to={`/client/${id}`} className="link" target="_blank">
                    {name}
                  </Link>
                </Table.RowData>
                <Table.RowData>{phoneNumber}</Table.RowData>
                <Table.RowData>{email}</Table.RowData>
                <Table.RowData>{city}</Table.RowData>
                <Table.RowData>{propertyCount}</Table.RowData>
                <Table.RowData>{unitCount}</Table.RowData>
              </Table.Row>
            );
          })}
        </Table>
      </Page>
      {showCreateClient && (
        <CreateClient onClose={() => toggleShowCreateClient(false)} />
      )}
    </>
  );
};

export default Clients;
