import "./Page.scss";
import React from "react";
import PropTypes from "prop-types";
import { Icon, LoadingSpinner } from "..";

const Page = ({
  totalCount,
  filters,
  children,
  currentPage,
  totalPageCount,
  onPreviousPage,
  onNextPage,
  loading,
  loadingLabel = "Loading..",
  additionalPageInfo = null,
}) => {
  return (
    <div className="page">
      <div className="page-header">
        <div className="total-count">
          {loading ? (
            <LoadingSpinner label={loadingLabel} />
          ) : (
            <h2>
              Found <strong>{totalCount}</strong> results
            </h2>
          )}
        </div>
        <div className="filters">{filters}</div>
      </div>
      <div className="page-body">{children}</div>
      <div className="page-footer">
        <div className="page-info">
          <span>
            Page <strong>{currentPage}</strong> /{" "}
            <strong>{totalPageCount + 1}</strong>
          </span>
          {additionalPageInfo}
        </div>
        <div className="page-controls">
          <button onClick={onPreviousPage}>
            <Icon name="arrowLeft" />
          </button>
          <button onClick={onNextPage}>
            <Icon name="arrowRight" />
          </button>
        </div>
      </div>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  filters: PropTypes.node,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPreviousPage: PropTypes.func,
  onNextPage: PropTypes.func,
  loading: PropTypes.bool,
  additionalPageInfo: PropTypes.node,
};

export default Page;
