import "./Select.scss";
import React from "react";
import PropTypes from "prop-types";
import Label from "../Label/Label";

import ReactSelect from "react-select";

const Select = ({ name, label, options, onChange, value, className }) => {
  const normalizedOptions = options.map(({ value, label }) => ({
    value,
    label: label || value,
  }));

  const onSelect = ({ value }) => {
    onChange({
      target: {
        name,
        value,
      },
    });
  };

  const selectedOption = normalizedOptions.find(
    (option) => option.value === value
  );

  return (
    <div className={`select ${className}`}>
      <Label htmlFor={name} label={label} />
      <div className="select-container">
        <ReactSelect
          classNamePrefix="react-select"
          value={selectedOption}
          onChange={onSelect}
          options={normalizedOptions}
        />
      </div>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Select;
