import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestRestApi } from "../../utilities";

const initialState = {
  uploading: false,
  totalCount: 0,
};

export const fetchTemplates = createAsyncThunk(
  "templates/fetchTemplates",
  async () => {
    try {
      const templates = await requestRestApi("/list_documents");

      return templates;
    } catch (error) {
      return null;
    }
  }
);

export const replaceTemplate = createAsyncThunk(
  "templates/replaceTemplate",
  async (formData) => {
    try {
      await requestRestApi("/replace_document", formData, "POST");

      window.location.reload();
    } catch (error) {
      window.location.reload();
    }
  }
);

const { reducer } = createSlice({
  name: "templates",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(replaceTemplate.pending, (state) => {
      state.uploading = true;
    });

    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      state.templates = action.payload;
      state.totalCount = action.payload?.length || 0;
    });
  },
});

export default reducer;
