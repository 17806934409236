import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestApi } from "../../utilities";
import { GET_CONTACTS, GET_CONTACT } from "../../queries";

const initialState = {
  fetching: false,
  totalCount: 0,
  contactsById: {},
};

export const fetchContacts = createAsyncThunk(
  "contacts/fetchContacts",
  async (variables) => {
    try {
      const { data } = await requestApi(GET_CONTACTS, variables);

      if (data.contacts.nodes === null) {
        return null;
      }

      return {
        contacts: data.contacts.nodes,
        totalCount: data.contacts.totalCount,
        pageInfo: data.contacts.pageInfo,
      };
    } catch {
      return null;
    }
  }
);

export const fetchContact = createAsyncThunk(
  "contacts/fetchContact",
  async (id) => {
    try {
      const { data } = await requestApi(GET_CONTACT, { id: parseInt(id) });

      return {
        id,
        contact: data.contact,
      };
    } catch {
      return {
        id,
        contact: null,
      };
    }
  }
);

const { reducer, actions } = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setExportedCount: (state, action) => {
      const ids = new Set(action.payload.ids);
      state.contacts.forEach((contact, idx) => {
        if (ids.has(contact.id)) {
          contact.exportedCount =
            contact.exportedCount === null ? 1 : contact.exportedCount + 1;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContacts.pending, (state, action) => {
      state.fetching = true;
    });

    builder.addCase(fetchContacts.fulfilled, (state, action) => {
      state.contacts = action.payload?.contacts || null;
      state.totalCount = action.payload?.totalCount ?? 0;
      state.pageInfo = action.payload?.pageInfo || null;
      state.fetching = false;
    });

    builder.addCase(fetchContact.fulfilled, (state, action) => {
      state.contactsById[action.payload.id] = action.payload.contact;
    });
  },
});

export const { setExportedCount } = actions;

export default reducer;
