import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchViolations, fetchViolation } from "./reducer";

export const useViolationsStore = (variables = {}) => {
  const dispatch = useDispatch();
  const fetching = useSelector((state) => state.violations.fetching);
  const violations = useSelector((state) => state.violations.violations);
  const totalCount = useSelector((state) => state.violations.totalCount);
  const pageInfo = useSelector((state) => state.violations.pageInfo);

  useEffect(() => {
    dispatch(fetchViolations(variables));
  }, [dispatch, variables]);

  return {
    fetching,
    violations,
    totalCount,
    pageInfo,
  };
};

export const useViolationStore = (id) => {
  const dispatch = useDispatch();
  const violation = useSelector((state) => state.violations.violationsById[id]);

  useEffect(() => {
    dispatch(fetchViolation(id));
  }, [dispatch, id]);

  return {
    violation,
  };
};
