import "./UpdateClient.scss";
import React, { useState } from "react";
import { Button, Icon, Modal } from "../components";
import { useDispatch } from "react-redux";
import { updateClient } from "../store/clients/reducer";

const FIELDS = [
  ["name", "text", "Client name"],
  ["phoneNumber", "text", "Phone"],
  ["email", "text", "Email"],
  ["city", "text", "Borough"],
  ["propertyCount", "text", "# of Properties"],
  ["unitCount", "number", "# of Units"],
];

const UpdateClient = ({ client, onClose }) => {
  const dispatch = useDispatch();

  const [state, updateState] = useState(client);

  const onChange = (event) => {
    updateState((currState) => ({
      ...currState,
      [event.target.name]:
        event.target.name === "unitCount"
          ? parseInt(event.target.value)
          : event.target.value,
    }));
  };

  const onSubmit = async () => {
    if (Object.keys(state).some((key) => state[key] === "")) {
      return;
    }

    const response = await dispatch(updateClient(state));

    if (!response.payload) {
      alert("Something went wrong");
      return;
    }

    onClose();
  };

  return (
    <Modal
      title="Edit client"
      onClose={onClose}
      footer={
        <Button onClick={onSubmit}>
          <Icon name="save" />
          Save
        </Button>
      }
    >
      <div className="create-client">
        {FIELDS.map((currField, idx) => {
          const [name, type, placeholder] = currField;
          return (
            <div key={idx} className="field">
              <label htmlFor={name}>{placeholder}</label>
              <input
                value={state[name]}
                name={name}
                type={type}
                onChange={onChange}
              />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default UpdateClient;
