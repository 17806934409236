import "./RecentJobs.scss";
import React from "react";
import { useJobsStore } from "../../store/jobs/hooks";
import { Icon } from "..";
import { formatDistance } from "date-fns";

const iconsByStatus = {
  COMPLETED: "checkAll",
  PROCESSING: "gear",
  FAILED: "close",
};

const RecentJobs = () => {
  const { jobs } = useJobsStore();

  if (!jobs || jobs?.length === 0) {
    return null;
  }

  return (
    <div className="recent-jobs">
      <div className="label">
        <h4>Recent Jobs</h4>
      </div>
      <div className="jobs">
        {jobs.map(({ status, startedAt, completedAt }, idx) => (
          <div className={`job ${status}`} key={idx}>
            <div className="job-icon">
              <Icon name={iconsByStatus[status]} />
            </div>
            <div className="job-metadata">
              <h4>{status}</h4>
              <h5>
                {formatDistance(
                  new Date(`${completedAt || startedAt}Z`),
                  new Date(),
                  { addSuffix: true }
                )}
              </h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentJobs;
