import React from "react";
import PropTypes from "prop-types";
import { BiBuildingHouse } from "react-icons/bi";
import {
  VscNote,
  VscCheckAll,
  VscSignOut,
  VscGear,
  VscChromeClose,
  VscChevronDown,
  VscArrowLeft,
  VscArrowRight,
  VscAccount,
  VscAdd,
  VscEdit,
  VscReferences,
  VscCloudUpload,
  VscCloudDownload,
} from "react-icons/vsc";
import { MdOutlineDirections } from "react-icons/md";
import { RiSearch2Line } from "react-icons/ri";
import { IoDocumentTextOutline, IoClose } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { BiSave } from "react-icons/bi";
import { MdOutlineDirectionsWalk } from "react-icons/md";

const icons = {
  building: <BiBuildingHouse />,
  note: <VscNote />,
  checkAll: <VscCheckAll />,
  signOut: <VscSignOut />,
  gear: <VscGear />,
  close: <VscChromeClose />,
  chevronDown: <VscChevronDown />,
  arrowLeft: <VscArrowLeft />,
  arrowRight: <VscArrowRight />,
  directions: <MdOutlineDirections />,
  search: <RiSearch2Line />,
  owners: <VscAccount />,
  document: <IoDocumentTextOutline />,
  cross: <IoClose />,
  clients: <FiUsers />,
  plus: <VscAdd />,
  pen: <VscEdit />,
  save: <BiSave />,
  references: <VscReferences />,
  upload: <VscCloudUpload />,
  download: <VscCloudDownload />,
  worker: <MdOutlineDirectionsWalk />,
};

const Icon = ({ name }) => {
  return icons[name] || null;
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(icons)),
};

export default Icon;
