const parseTime = (str) => {
  if (str.length === 2) {
    // 01
    return str + ":";
  }
  if (str.length === 5) {
    // 01:22
    return str + ":";
  }

  return str;
};

export default parseTime;
