import "./Label.scss";
import React from "react";
import PropTypes from "prop-types";

const Label = ({ htmlFor, label }) => {
  return (
    <label htmlFor={htmlFor} className="label">
      {label}
    </label>
  );
};

Label.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.string,
};

export default Label;
