import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestApi } from "../../utilities";
import {
  CREATE_CLIENT,
  GET_CLIENTS,
  GET_CLIENT,
  UPDATE_CLIENT,
  GET_PROPERTY_AND_UNIT_COUNT,
} from "../../queries";

const initialState = {
  clientsById: {},
  creatingClient: false,
  updatingClient: false,
  fetching: false,
  fetchingCounts: false,
  totalCount: 0,
  propertyCount: 0,
  unitCount: 0,
};

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async (variables) => {
    try {
      const { data } = await requestApi(GET_CLIENTS, variables);

      if (data.clients.nodes === null) {
        return null;
      }

      return {
        clients: data.clients.nodes,
        totalCount: data.clients.totalCount,
        pageInfo: data.clients.pageInfo,
      };
    } catch {
      return null;
    }
  }
);

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (input) => {
    try {
      const { data } = await requestApi(CREATE_CLIENT, input);

      if (data.createClient.client === null) {
        return null;
      }

      return {
        id: data.createClient.client.id,
      };
    } catch {
      return null;
    }
  }
);

export const fetchClient = createAsyncThunk(
  "clients/fetchClient",
  async (id) => {
    try {
      const { data } = await requestApi(GET_CLIENT, { id: parseInt(id) });

      return {
        id,
        client: data.client,
      };
    } catch {
      return {
        id,
        client: null,
      };
    }
  }
);

export const updateClient = createAsyncThunk(
  "clients/updateClient",
  async (input) => {
    try {
      const { data } = await requestApi(UPDATE_CLIENT, input);

      if (data.updateClientById.client === null) {
        return null;
      }

      return data.updateClientById.client;
    } catch {
      return null;
    }
  }
);

export const fetchPropertyAndUnitCount = createAsyncThunk(
  "clients/fetchPropertyAndUnitCount",
  async () => {
    try {
      const { data } = await requestApi(GET_PROPERTY_AND_UNIT_COUNT);
      if (!data.allClients.nodes) {
        return null;
      }

      const { nodes } = data.allClients;

      return nodes.reduce(
        (acc, node) => {
          const propertyCount = Number(node.propertyCount);
          const unitCount = Number(node.unitCount);

          if (!isNaN(propertyCount)) {
            acc.propertyCount = acc.propertyCount + propertyCount;
          }

          if (!isNaN(unitCount)) {
            acc.unitCount = acc.unitCount + unitCount;
          }

          return acc;
        },
        { propertyCount: 0, unitCount: 0 }
      );
    } catch (error) {
      return null;
    }
  }
);

const { reducer } = createSlice({
  name: "clients",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchClients.pending, (state, action) => {
      state.fetching = true;
    });

    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.clients = action.payload?.clients || null;
      state.totalCount = action.payload?.totalCount ?? 0;
      state.pageInfo = action.payload?.pageInfo || null;
      state.fetching = false;
    });

    builder.addCase(createClient.pending, (state) => {
      state.creatingClient = true;
    });

    builder.addCase(createClient.fulfilled, (state) => {
      state.creatingClient = false;
    });

    builder.addCase(fetchClient.fulfilled, (state, action) => {
      state.clientsById[action.payload.id] = action.payload.client;
    });

    builder.addCase(updateClient.pending, (state) => {
      state.updatingClient = true;
    });

    builder.addCase(updateClient.fulfilled, (state, action) => {
      state.updatingClient = false;
      if (!action.payload) {
        return;
      }

      state.clientsById[action.payload.id] = action.payload;
    });

    builder.addCase(fetchPropertyAndUnitCount.pending, (state) => {
      state.fetchingCounts = true;
    });

    builder.addCase(fetchPropertyAndUnitCount.fulfilled, (state, action) => {
      state.fetchingCounts = true;

      if (!action.payload) {
        return;
      }

      state.propertyCount = action.payload.propertyCount;
      state.unitCount = action.payload.unitCount;
    });
  },
});

export default reducer;
