import { useMemo } from "react";
import { useInspectorsStore } from "../../../store/inspectors/hooks";

export const useInspectors = () => {
  const { inspectors: rawInspectors } = useInspectorsStore();

  const inspectorsByName = useMemo(() => {
    if (!rawInspectors) {
      return rawInspectors;
    }

    return rawInspectors.reduce((acc, inspector) => {
      acc[inspector.name] = {
        name: inspector.name,
        certificationNumber: inspector.certNumber,
        certificationExpirationDate: inspector.certExpiration,
        instrumentSerialNumber: inspector.instrumentNumber,
      };
      return acc;
    }, {});
  }, [rawInspectors]);

  const inspectorsInputName = useMemo(() => {
    if (!inspectorsByName) {
      return [];
    }

    return Object.keys(inspectorsByName).map((value) => ({
      value,
    }));
  }, [inspectorsByName]);

  return {
    inspectorsByName,
    inspectorsInputName,
  };
};
