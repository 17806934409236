import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTemplates, replaceTemplate } from "./reducer";

export const useTemplatesStore = () => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templates.templates);
  const totalCount = useSelector((state) => state.templates.totalCount);

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  return {
    templates,
    totalCount,
  };
};

export const useReplaceTemplate = () => {
  const dispatch = useDispatch();
  const uploading = useSelector((state) => state.templates.uploading);

  const onReplaceTemplate = useCallback(
    (file, filename) => {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("filename", filename);

      dispatch(replaceTemplate(formData));
    },
    [dispatch]
  );

  return {
    uploading,
    onReplaceTemplate,
  };
};
