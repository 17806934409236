import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPremises, fetchPremisesByClient } from "./reducer";

export const usePremisesStore = (clientId) => {
  const dispatch = useDispatch();

  const premiseById = useSelector((state) => state.premises.premiseById);
  const premisesByClient = useSelector(
    (state) => state.premises.premisesByClient?.[clientId]
  );

  useEffect(() => {
    if (!premisesByClient) {
      dispatch(fetchPremisesByClient(clientId));
    }
  }, [dispatch, clientId, premisesByClient]);

  return {
    fetching: premisesByClient === undefined,
    premises: premisesByClient?.map((id) => premiseById[id]),
  };
};

export const useAllPremisesStore = () => {
  const dispatch = useDispatch();

  const fetching = useSelector((state) => state.premises.fetchingAllPremises);
  const premises = useSelector((state) =>
    Object.values(state.premises.premiseById)
  );

  useEffect(() => {
    dispatch(fetchAllPremises());
  }, [dispatch]);

  return {
    fetching,
    premises,
  };
};
