import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchContacts, fetchContact } from "./reducer";

export const useContactsStore = (variables = {}) => {
  const dispatch = useDispatch();
  const fetching = useSelector((state) => state.contacts.fetching);
  const contacts = useSelector((state) => state.contacts.contacts);
  const totalCount = useSelector((state) => state.contacts.totalCount);
  const pageInfo = useSelector((state) => state.contacts.pageInfo);

  useEffect(() => {
    dispatch(fetchContacts(variables));
  }, [dispatch, variables]);

  return {
    fetching,
    contacts,
    totalCount,
    pageInfo,
  };
};

export const useContactStore = (id) => {
  const dispatch = useDispatch();
  const contact = useSelector((state) => state.contacts.contactsById[id]);

  useEffect(() => {
    dispatch(fetchContact(id));
  }, [dispatch, id]);

  return {
    contact,
  };
};
