import "./LoadingSpinner.scss";
import React from "react";

const LoadingSpinner = ({ label }) => {
  return (
    <div className="loading-container">
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span>{label}</span>
    </div>
  );
};

export default LoadingSpinner;
