import "./Modal.scss";
import React from "react";
import { createPortal } from "react-dom";
import { Icon } from "../";

const Modal = ({ title, children, footer, onClose }) => {
  return createPortal(
    <div className="modal-container">
      <div className="modal">
        <div className="modal-header">
          <h2>{title}</h2>
          <button className="close-modal" onClick={onClose}>
            <Icon name="cross" />
          </button>
        </div>
        <div className="modal-body">{children}</div>
        {footer && <div className="modal-footer">{footer}</div>}
      </div>
    </div>,
    document.querySelector("body")
  );
};

export default Modal;
