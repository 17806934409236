import "./CreateInspector.scss";
import React, { useState } from "react";
import { Button, Icon, Modal } from "../components";
import { useDispatch } from "react-redux";
import { createInspector } from "../store/inspectors/reducer";

const FIELDS = [
  ["name", "text", "Inspectors' name"],
  ["certNumber", "text", "Certificate #"],
  ["certExpiration", "date", "Certificate Expiration"],
  ["instrumentNumber", "number", "Gun #"],
];

const normalizerByFieldName = {
  instrumentNumber: (value) => parseInt(value),
};

const initialState = FIELDS.reduce((acc, [name]) => {
  acc[name] = "";

  return acc;
}, {});

const CreateInspector = ({ onClose }) => {
  const dispatch = useDispatch();

  const [state, updateState] = useState(initialState);

  const onChange = (event) => {
    updateState((currState) => ({
      ...currState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = async () => {
    if (Object.keys(state).some((key) => state[key] === "")) {
      return;
    }

    const inspector = {};

    for (const fieldName in state) {
      inspector[fieldName] =
        typeof normalizerByFieldName[fieldName] === "function"
          ? normalizerByFieldName[fieldName](state[fieldName])
          : state[fieldName];
    }

    const response = await dispatch(createInspector(inspector));

    if (!response.payload) {
      alert("Something went wrong");
      return;
    }

    onClose();

    window.location.reload();
  };

  return (
    <Modal
      title="Create inspector"
      onClose={onClose}
      footer={
        <Button onClick={onSubmit}>
          <Icon name="plus" />
          Create
        </Button>
      }
    >
      <div className="create-inspector">
        <div>
          {FIELDS.map((currField, idx) => {
            const [name, type, placeholder] = currField;
            return (
              <div key={idx} className="field">
                <label htmlFor={name}>{placeholder}</label>
                <input
                  value={state[name]}
                  name={name}
                  type={type}
                  onChange={onChange}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default CreateInspector;
