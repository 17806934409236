import qs from "qs";

const parseQueryString = (search, defaultValues) => {
  if (!search || search === "") {
    return defaultValues;
  }

  const parsed = qs.parse(search.replace("?", ""), {
    parseNumbers: true,
  });

  for (const key in parsed) {
    if (typeof defaultValues[key] === "number") {
      parsed[key] = parseInt(parsed[key]);
    }
  }

  return parsed;
};

export default parseQueryString;
