import "./Form.scss";
import React, { useState, useMemo } from "react";
import { Select } from "../../components";
import { parseTime } from "../../utilities";
import { SelectPremise } from "./SelectPremise";
import { XRF_GUNS } from "./constants";
import { useInspectors } from "./hooks/useInspectors";

const GENERAL_FIELDS = [
  ["premisesApartmentNumber", "text", "Apartment Number"],
  ["letterDate", "date", "Letter date"],
  ["inspectionDate", "date", "Inspection date"],
  ["jobId", "text", "Job id"],
  ["startTime", "text", "Start time"],
  ["endTime", "text", "End time"],
  ["totalReadings", "text", "Total readings"],
  ["totalCalibrations", "text", "Total calibrations"],
  ["totalPositive", "text", "Total positives"],
];

const PREMISES_FIELDS = [
  ["premisesCorporationName", "text", "Corporation Name"],
  ["premisesCorporationAddress", "text", "Corporation Address"],
  ["premisesCorporationCity", "text", "Corporation City"],
  ["premisesCorporationState", "text", "Corporation State"],
  ["premisesCorporationZip", "text", "Corporation Zip"],
  ["premisesAddress", "text", "Address"],
  ["premisesCity", "text", "City"],
  ["premisesState", "text", "State", "NY"],
  ["premisesZip", "text", "ZIP Code"],
  ["premisesRegistrationId", "text", "Registration ID"],
  ["premisesBin", "text", "Bin"],
  ["premisesBlock", "text", "Block"],
  ["premisesLot", "text", "Lot"],
  ["premisesBorough", "text", "Borough"],
];

const INSPECTOR_FIELDS = [
  ["instrumentSerialNumber", "select", "Gun Serial Number", XRF_GUNS],
  ["inspectorName", "select", "Inspector name"],
  ["certificationNumber", "text", "Certification #"],
  ["certificationExpirationDate", "date", "Certification Expiration Date"],
  ["firmName", "text", "Firm name", "RAZA ENVIRONMENTAL INC."],
];

const getInitialState = () => {
  return [...GENERAL_FIELDS, ...PREMISES_FIELDS, ...INSPECTOR_FIELDS].reduce(
    (acc, field) => {
      const [key, , , value = ""] = field;

      if (key === "totalCalibrations") {
        acc[key] = "12";
      } else {
        acc[key] = value;
      }

      return acc;
    },
    {}
  );
};

export const XRFFrictionForm = ({ onCreate }) => {
  const [positive, setPositive] = useState(false);
  const [state, setState] = useState(getInitialState());
  const { inspectorsByName, inspectorsInputName } = useInspectors();

  const onChange = (event) => {
    const key = event.target.name;
    const value = key.match("startTime|endTime")
      ? parseTime(event.target.value)
      : event.target.value;

    setState({
      ...state,
      [key]: value,
    });
  };

  const inspectorFields = useMemo(() => {
    if (!inspectorsInputName) {
      return INSPECTOR_FIELDS;
    }

    return INSPECTOR_FIELDS.map((curr) => {
      const [key] = curr;

      if (key === "inspectorName") {
        return [...curr, inspectorsInputName];
      }

      return curr;
    });
  }, [inspectorsInputName]);

  const onSelect = (event) => {
    const key = event.target.name;

    if (key === "inspectorName") {
      const inspectorName = event.target.value;

      const inspectorInfo = inspectorsByName[inspectorName] || {};

      setState({
        ...state,
        inspectorName,
        ...inspectorInfo,
      });
      return;
    }

    setState({
      ...state,
      [key]: event.target.value,
    });
  };

  const onTogglePositive = () => {
    setPositive(!positive);
  };

  const onClear = () => {
    setState(getInitialState());
    setPositive(!positive);
  };

  const onSubmit = () => {
    const fields = Object.keys(state).reduce((acc, key) => {
      if (key.match("letterDate|inspectionDate|certificationExpirationDate")) {
        const [year, month, day] = state[key].split("-");
        acc[key] = month + "/" + day + "/" + year;
      } else {
        acc[key] = state[key];
      }

      return acc;
    }, {});

    onCreate({
      fields,
      positive,
      type: "xrf-friction",
    });
  };

  const onSelectPremise = (premise) => {
    setState((currentState) => ({
      ...currentState,
      premisesAddress: premise.address,
      premisesCity: premise.city,
      premisesState: premise.state,
      premisesZip: premise.zip,
      premisesRegistrationId: premise.registrationId,
      premisesBin: premise.bin,
      premisesBlock: premise.block,
      premisesLot: premise.lot,
      premisesBorough: premise.borough,
      premisesCorporationName: premise.corporationName,
      premisesCorporationAddress: premise.corporationAddress,
      premisesCorporationCity: premise.corporationCity,
      premisesCorporationState: premise.corporationState,
      premisesCorporationZip: premise.corporationZip,
    }));
  };

  return (
    <div className="create-documents-form">
      <div className="create-documents-form-group">
        <div className="group-label">
          <h4>Premise</h4>
        </div>
        <SelectPremise onSelectPremise={onSelectPremise} />
      </div>
      <div className="create-documents-form-group">
        <div className="group-label">
          <h4>General</h4>
        </div>
        <Fields fields={GENERAL_FIELDS} state={state} onChange={onChange} />
      </div>
      <div className="create-documents-form-group">
        <div className="group-label">
          <h4>Inspector</h4>
        </div>
        <Fields
          fields={inspectorFields}
          state={state}
          onChange={onChange}
          onSelect={onSelect}
        />
      </div>
      <div className="create-documents-form-group">
        <div className="group-label">
          <h4>Premises</h4>
        </div>
        <Fields fields={PREMISES_FIELDS} state={state} onChange={onChange} />
      </div>
      <div className="create-documents-form-group">
        <div className="group-label">
          <h4>Other</h4>
        </div>
        <div className="group-fields">
          <div className="boolean-field">
            <h4>Lead Present:</h4>
            <div className="boolean-field-options">
              <button
                onClick={onTogglePositive}
                className={positive ? "" : "selected"}
              >
                No
              </button>
              <button
                onClick={onTogglePositive}
                className={positive ? "selected" : ""}
              >
                Yes
              </button>
            </div>
          </div>
          &nbsp;
        </div>
      </div>
      &nbsp;
      <div className="create-documents-form-buttons">
        <div>
          <button onClick={onClear}>Clear</button>
          <button onClick={onSubmit} className="create">
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

const Fields = ({ fields, state, onChange, onSelect }) => {
  return (
    <div className="group-fields">
      {fields.map((field, idx) => {
        const [name, type, placeholder, options] = field;
        const key = field + "-" + idx;

        if (type === "select") {
          return (
            <div key={key} className="field">
              <Select
                name={name}
                label={placeholder}
                value={state[name]}
                options={options}
                onChange={onSelect}
                className="inline-select"
              />
            </div>
          );
        }

        return (
          <div key={key} className="field">
            <label htmlFor={name}>{placeholder}</label>
            <input
              value={state[name]}
              name={name}
              type={type}
              onChange={onChange}
            />
          </div>
        );
      })}
    </div>
  );
};
