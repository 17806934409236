import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClients,
  fetchClient,
  fetchPropertyAndUnitCount,
} from "./reducer";

export const useClientsStore = (variables = {}) => {
  const dispatch = useDispatch();
  const fetching = useSelector((state) => state.clients.fetching);
  const clients = useSelector((state) => state.clients.clients);
  const totalCount = useSelector((state) => state.clients.totalCount);
  const pageInfo = useSelector((state) => state.clients.pageInfo);

  useEffect(() => {
    dispatch(fetchClients(variables));
  }, [dispatch, variables]);

  return {
    fetching,
    clients,
    totalCount,
    pageInfo,
  };
};

export const useClientStore = (id) => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.clients.clientsById[id]);

  useEffect(() => {
    dispatch(fetchClient(id));
  }, [dispatch, id]);

  return {
    client,
  };
};

export const usePropertyAndUnitCount = () => {
  const dispatch = useDispatch();
  const fetching = useSelector((state) => state.clients.fetchingCounts);
  const propertyCount = useSelector((state) => state.clients.propertyCount);
  const unitCount = useSelector((state) => state.clients.unitCount);

  useEffect(() => {
    dispatch(fetchPropertyAndUnitCount());
  }, [dispatch]);

  return {
    fetching,
    propertyCount,
    unitCount,
  };
};
