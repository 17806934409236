import "./Contact.scss";
import React from "react";
import { Table, LoadingSpinner, Icon } from "../../components";
import { useContactStore } from "../../store/contacts/hooks";
import { useParams } from "react-router";

const Contact = () => {
  const { id } = useParams();
  const { contact } = useContactStore(id);

  if (contact === undefined) {
    return (
      <div style={{ margin: "1rem" }}>
        <LoadingSpinner label="Loading contact" />
      </div>
    );
  }

  if (contact === null) {
    return <h1 color="white">Violation not found</h1>;
  }

  const {
    firstName,
    lastName,
    corporationName,
    type,
    streetName,
    houseNumber,
    apartment,
    city,
    state,
    zip,
    exportedCount,
    registrations,
  } = contact;

  const buildings = registrations?.nodes?.[0]?.buildings;
  const associatedBuildings = buildings?.nodes || [];

  return (
    <div className="contact">
      <div className="contact-header">
        <h1>
          Contact: <strong>{id}</strong>
        </h1>
      </div>
      <div className="contact-body">
        <div className="pair">
          <div className="pair-name">Full Name</div>
          <div className="pair-value">
            {!firstName && !lastName ? "N/A" : `${firstName} ${lastName}`}
          </div>
        </div>
        <div className="pair">
          <div className="pair-name">Corporation Name</div>
          <div className="pair-value">{corporationName}</div>
        </div>
        <div className="pair">
          <div className="pair-name">Type</div>
          <div className="pair-value">{type}</div>
        </div>
        <div className="pair">
          <div className="pair-name">Address</div>
          <div className="pair-value">
            {streetName
              ? `${houseNumber} ${streetName} ${
                  apartment || ""
                }, ${city} ${state} ${zip} `
              : ""}
          </div>
        </div>
        <div className="pair">
          <div className="pair-name">Times exported</div>
          <div className="pair-value">{exportedCount || 0}</div>
        </div>
      </div>
      <AssociatedBuildings buildings={associatedBuildings} />
    </div>
  );
};

const AssociatedBuildings = ({ buildings = [] }) => {
  if (!buildings || !Array.isArray(buildings)) {
    return null;
  }

  return (
    <div className="contact-buildings">
      <div className="contact-buildings-header">Associated with buildings</div>
      <Table
        headers={[
          "ID",
          "House Number",
          "Street Name",
          "Post code",
          "Borough",
          "Total Units",
          "Legal Stories",
          "Year built",
          "Actions",
        ]}
      >
        {buildings.map((building, idx) => {
          const {
            id,
            houseNumber,
            streetName,
            postCode,
            borough,
            stories,
            additionalInfo,
          } = building;
          const destinationQuery = `${houseNumber} ${streetName} ${borough} ${
            postCode ?? ""
          }`
            .replaceAll(" ", "+")
            .trim();
          return (
            <Table.Row key={idx}>
              <Table.RowData>{id}</Table.RowData>
              <Table.RowData>{houseNumber}</Table.RowData>
              <Table.RowData>{streetName}</Table.RowData>
              <Table.RowData>{postCode || "N/A"}</Table.RowData>
              <Table.RowData>{borough}</Table.RowData>
              <Table.RowData>
                {additionalInfo?.units || "Not known"}
              </Table.RowData>
              <Table.RowData>{stories}</Table.RowData>
              <Table.RowData>
                {additionalInfo?.year || "Not known"}
              </Table.RowData>
              <Table.RowData>
                <a
                  href={`https://www.google.com/maps?saddr=My+Location&daddr=${destinationQuery}`}
                  target="_blank"
                  rel="noreferrer"
                  className="directions"
                >
                  <Icon name="directions" />
                  Get directions
                </a>{" "}
              </Table.RowData>
            </Table.Row>
          );
        })}
      </Table>
    </div>
  );
};

export default Contact;
