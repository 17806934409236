import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestApi } from "../../utilities";
import { GET_BUILDINGS } from "../../queries";

const initialState = {
  fetching: false,
  totalCount: 0,
};

export const fetchBuildings = createAsyncThunk(
  "buildings/fetchBuildings",
  async (variables) => {
    try {
      const { data } = await requestApi(GET_BUILDINGS, variables);

      if (data.buildings.nodes === null) {
        return null;
      }

      return {
        buildings: data.buildings.nodes,
        totalCount: data.buildings.totalCount,
        pageInfo: data.buildings.pageInfo,
      };
    } catch {
      return null;
    }
  }
);

const { reducer } = createSlice({
  name: "buildings",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchBuildings.pending, (state, action) => {
      state.fetching = true;
    });

    builder.addCase(fetchBuildings.fulfilled, (state, action) => {
      state.buildings = action.payload?.buildings || null;
      state.totalCount = action.payload?.totalCount ?? 0;
      state.pageInfo = action.payload?.pageInfo || null;
      state.fetching = false;
    });
  },
});

export default reducer;
