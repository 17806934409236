import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  string: null,
};

const { actions, reducer } = createSlice({
  name: "search",
  initialState,
  reducers: {
    setString: (state, action) => {
      state.string = action.payload;
    },
  },
});

export const { setString } = actions;
export default reducer;
