import React, { useState, useEffect } from "react";
import { useContactsStore } from "../../store/contacts/hooks";
import {
  LoadingSpinner,
  Page,
  Table,
  Select,
  Toggle,
  Field,
  Label,
} from "../../components";
import {
  RESULTS_LIMIT,
  TYPES,
  SORTING_OPTIONS,
  STORIES_OPTIONS,
} from "./constants";
import Export from "./Export";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Contacts = () => {
  const search = useSelector((state) => state.search.string);

  const [variables, updateVariables] = useState({
    first: 100,
    offset: 0,
    orderBy: "ZIP_ASC",
  });

  const { fetching, totalCount, contacts, pageInfo } =
    useContactsStore(variables);

  useEffect(() => {
    const vars = { ...variables };
    if (!search) {
      if (vars?.filter?.search?.matches) {
        vars.offset = 0;
        delete vars.filter.search;

        if (Object.keys(vars.filter).length === 0) {
          delete vars.filter;
        }

        updateVariables(vars);
      }
      return;
    } else if (vars?.filter?.search?.matches !== search) {
      vars.filter = {
        ...vars.filter,
        search: {
          matches: search,
        },
      };
      vars.offset = 0;
      updateVariables(vars);
    }
  }, [search, variables]);

  if (contacts === undefined) {
    return (
      <div style={{ margin: "1rem" }}>
        <LoadingSpinner label="Loading contacts" />
      </div>
    );
  }

  const onFilterChange = (event) => {
    const { name, value } = event.target;
    const vars = { ...variables, offset: 0 };

    if (name === "resultsPerPage") {
      vars.first = parseInt(value);
    }

    if (name === "sortBy") {
      vars.orderBy = value;
    }

    if (name === "filterByType") {
      if (value === "") {
        delete vars.condition;
      } else {
        vars.first = vars.first || 100;
        vars.condition = {
          type: value,
        };
      }
    }

    if (name === "contactsWithViolationsOnly") {
      if (!value) {
        delete vars.filter;
      } else {
        const currFilter = {
          and: {
            registrationsByContactId: {
              some: { violationsByRegistrationIdExist: true },
            },
          },
        };

        vars.filter = vars.filter
          ? {
              ...vars.filter,
              ...currFilter,
            }
          : currFilter;
      }
    }

    if (name === "violationsCreatedOnOrAfter") {
      const currFilter = {
        registrationsByContactId: {
          some: {
            violationsByRegistrationId: {
              some: { createdAt: { greaterThanOrEqualTo: value } },
            },
          },
        },
      };

      vars.filter = vars.filter
        ? {
            ...vars.filter,
            ...currFilter,
          }
        : currFilter;
    }

    if (name === "")
      if (name === "filterByStories") {
        if (value === "all") {
          delete vars.filter;
        } else {
          vars.filter = {
            registrationsByContactId: {
              some: {
                buildingsByRegistrationId: {
                  some: { stories: { greaterThanOrEqualTo: parseInt(value) } },
                },
              },
            },
          };
        }
      }

    if (
      vars.filter?.registrationsByContactId?.some
        ?.violationsByRegistrationIdExist &&
      vars.condition?.type === "CorporateOwner"
    ) {
      delete vars.first;
    }

    updateVariables(vars);
  };

  const onPreviousPage = () => {
    if (!pageInfo.hasPreviousPage) {
      return;
    }

    updateVariables({
      ...variables,
      offset: variables.offset - variables.first,
    });
  };

  const onNextPage = () => {
    if (!pageInfo.hasNextPage) {
      return;
    }

    updateVariables({
      ...variables,
      offset: variables.offset + variables.first,
    });
  };

  const filters = [
    <Toggle
      key="contactsWithViolationsOnly"
      name="contactsWithViolationsOnly"
      label="Contacts with violations only"
      onChange={onFilterChange}
    />,
    <div className="date-filter">
      <Label
        htmlFor="violationsCreatedOnOrAfter"
        label="Got violation on or after"
      />
      <Field
        key="violationsCreatedOnOrAfter"
        type="date"
        name="violationsCreatedOnOrAfter"
        onChange={onFilterChange}
      />
    </div>,
    <Select
      key="sortBy"
      name="sortBy"
      label="Sort by"
      options={SORTING_OPTIONS}
      onChange={onFilterChange}
      value={variables.orderBy}
    />,
    <Select
      key="resultsPerPage"
      name="resultsPerPage"
      label="Results per page"
      options={RESULTS_LIMIT}
      onChange={onFilterChange}
      value={variables.first?.toString() || "100"}
    />,
    <Select
      key="filterByType"
      name="filterByType"
      label="Filter by type"
      options={TYPES}
      onChange={onFilterChange}
      value={variables.condition?.type || ""}
    />,
    <Select
      key="filterByStories"
      name="filterByStories"
      label="Stories"
      options={STORIES_OPTIONS}
      onChange={onFilterChange}
      value={
        variables.filter?.registrationsByContactId?.some
          ?.buildingsByRegistrationId?.some?.stories?.greaterThanOrEqualTo || ""
      }
    />,
  ];

  if (variables.condition?.type) {
    filters.push(
      <div className="filters-export">
        <Export
          key="export-current"
          type="current"
          label="Export"
          variables={variables}
        />
        ,
        <Export
          key="export-all"
          type="all"
          label="Export All"
          variables={variables}
          totalCount={totalCount}
        />
      </div>
    );
  }

  const pageProps = {
    totalCount,
    filters,
    onPreviousPage,
    onNextPage,
  };

  const totalPageCount =
    variables.first === undefined
      ? 1
      : Math.round(totalCount / variables.first);
  const currentPage =
    totalPageCount === 1 ? 1 : variables.offset / variables.first + 1;

  return (
    <Page
      {...pageProps}
      currentPage={currentPage}
      totalPageCount={totalPageCount}
      loading={fetching}
    >
      <Table
        headers={[
          "ID",
          "Full Name",
          "Corporation Name",
          "Type",
          "Address",
          "Times exported",
        ]}
      >
        {contacts.map((owner, idx) => {
          const {
            id,
            firstName,
            lastName,
            corporationName,
            type,
            houseNumber,
            streetName,
            apartment,
            city,
            state,
            zip,
            exportedCount,
          } = owner;

          return (
            <Table.Row key={idx}>
              <Table.RowData>
                <Link className="link" to={`/contact/${id}`} target="_blank">
                  {id}
                </Link>
              </Table.RowData>
              <Table.RowData>
                {!firstName && !lastName ? "N/A" : `${firstName} ${lastName}`}
              </Table.RowData>
              <Table.RowData>{corporationName}</Table.RowData>
              <Table.RowData>{type}</Table.RowData>
              <Table.RowData>
                {streetName
                  ? `${houseNumber} ${streetName} ${
                      apartment || ""
                    }, ${city} ${state} ${zip} `
                  : ""}
              </Table.RowData>
              <Table.RowData>{exportedCount || 0}</Table.RowData>
            </Table.Row>
          );
        })}
      </Table>
    </Page>
  );
};

export default Contacts;
