export const INSPECTOR_INFORMATION = {
  "Ramo Kucevic": {
    certificationNumber: "LBP-I-I232470-1",
    certificationExpirationDate: "2025-02-13",
    instrumentSerialNumber: 3284,
  },
  "Zamo Kucevic": {
    certificationNumber: "LBP-I-I227161-1",
    certificationExpirationDate: "2024-09-27",
    instrumentSerialNumber: 3276,
  },
  "Sanela Lekovic": {
    certificationNumber: "LBP-I-I236160-1",
    certificationExpirationDate: "2026-02-06",
    instrumentSerialNumber: 3069,
  },
  "Jela Miric Lalic": {
    certificationNumber: "LBP-I-212067-1",
    certificationExpirationDate: "2023-02-11",
  },
  "Elvis Luma": {
    certificationNumber: "LBP-I-I244816-1",
    certificationExpirationDate: "2027-04-15",
    instrumentSerialNumber: 3234,
  },
  "Aaron Brandon Gonzalez": {
    certificationNumber: "LBP-I-I244320-1",
    certificationExpirationDate: "2026-02-02",
    instrumentSerialNumber: 3284,
  },
  "Anel Djesevic": {
    certificationNumber: "I-I-182736-23-00019",
    certificationExpirationDate: "2023-08-10",
    instrumentSerialNumber: 3125,
  },
  "Halil Lajqi": {
    certificationNumber: "LII-24-5537",
    certificationExpirationDate: "2024-07-24",
    instrumentSerialNumber: 3151,
  },
  "Omer Radoncic": {
    certificationNumber: "I-I-182736-23-00351",
    certificationExpirationDate: "2024-06-20",
    instrumentSerialNumber: 3125,
  },
  "Eskiender Dzhemiliev": {
    certificationNumber: "I-I-182736-24-00102",
    certificationExpirationDate: "2024-09-20",
    instrumentSerialNumber: 3276,
  },
  "Dardan Brovina": {
    certificationNumber: "LII-24-8447",
    certificationExpirationDate: "2024-11-08",
    instrumentSerialNumber: 3284
  }
};

export const XRF_GUNS = [3069, 3125, 3151, 3234, 3276, 3284].map((value) => ({
  value,
}));

export const INSPECTORS = Object.keys(INSPECTOR_INFORMATION).map((value) => ({
  value,
}));
