import "./Table.scss";
import React from "react";
import PropTypes from "prop-types";

const Table = ({ children, headers = [] }) => {
  return (
    <table>
      <thead>
        <tr>
          {headers.map((header, idx) => (
            <th key={idx}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

Table.propTypes = {
  children: PropTypes.node,
  headers: PropTypes.arrayOf(PropTypes.string),
};

const Row = ({ children, style = {} }) => {
  return <tr style={style}>{children}</tr>;
};

const RowData = ({ children, style }) => {
  return <td style={style}>{children}</td>;
};

Table.Row = Row;
Table.RowData = RowData;

export default Table;
