import "./Violation.scss";
import React from "react";
import { Table, LoadingSpinner } from "../../components";
import { useViolationStore } from "../../store/violations/hooks";
import { useParams } from "react-router";
import { formatDistance } from "date-fns";

const Violation = () => {
  const { id } = useParams();
  const { violation } = useViolationStore(id);

  if (violation === undefined) {
    return (
      <div style={{ margin: "1rem" }}>
        <LoadingSpinner label="Loading violation" />
      </div>
    );
  }

  if (violation === null) {
    return <h1 color="white">Violation not found</h1>;
  }

  const {
    orderNumber,
    createdAt,
    building: { houseNumber, streetName, borough, postCode },
    registration: { contact },
  } = violation;

  const buildingAddress = `
            ${houseNumber} ${streetName}, ${borough} ${postCode ?? ""}`;

  return (
    <div className="violation">
      <div className="violation-header">
        <h1>
          Violation: <strong>{id}</strong>
        </h1>
      </div>
      <div className="violation-body">
        <div className="pair">
          <div className="pair-name">Order Number</div>
          <div className="pair-value">{orderNumber}</div>
        </div>
        <div className="pair">
          <div className="pair-name">Address</div>
          <div className="pair-value">{buildingAddress}</div>
        </div>
        <div className="pair">
          <div className="pair-name">Created</div>
          <div className="pair-value">
            {formatDistance(new Date(`${createdAt}Z`), new Date(), {
              addSuffix: true,
            })}
          </div>
        </div>
      </div>
      <AssociationContacts contact={contact} />
    </div>
  );
};

const AssociationContacts = ({ contact }) => {
  if (contact === null) {
    return null;
  }

  const {
    id,
    firstName,
    lastName,
    corporationName,
    type,
    houseNumber,
    streetName,
    apartment,
    city,
    state,
    zip,
  } = contact;

  return (
    <div className="violation-contacts">
      <div className="violation-contacts-header">Associated contacts</div>
      <Table
        headers={["ID", "Full Name", "Corporation Name", "Type", "Address"]}
      >
        <Table.Row>
          <Table.RowData>{id}</Table.RowData>
          <Table.RowData>
            {!firstName && !lastName ? "N/A" : `${firstName} ${lastName}`}
          </Table.RowData>
          <Table.RowData>{corporationName}</Table.RowData>
          <Table.RowData>{type}</Table.RowData>
          <Table.RowData>
            {streetName
              ? `${houseNumber} ${streetName} ${
                  apartment || ""
                }, ${city} ${state} ${zip} `
              : ""}
          </Table.RowData>
        </Table.Row>
      </Table>
    </div>
  );
};

export default Violation;
