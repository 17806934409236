import "./CreatedDocumentsList.scss";
import React from "react";
import { Icon, Modal } from "../../components";

const CreatedDocumentsList = ({ files, onCloseModal }) => {
  if (files.length === 0) {
    return null;
  }

  return (
    <Modal title="Created documents" onClose={onCloseModal}>
      <div className="created-documents-list">
        {files.map(({ filename, link }, idx) => (
          <div className="created-document-link" key={idx}>
            <div className="meta">
              <Icon name="document" />
              <div className="meta-file">
                <span className="filename">{filename}</span>
              </div>
            </div>
            <div className="link">
              <a download={filename} href={link}>
                Download
              </a>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default CreatedDocumentsList;
