import "./Links.scss";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "..";

const NAVIGATION_LINKS = [
  ["Violations", "note"],
  ["Buildings", "building"],
  ["Contacts", "owners"],
  ["Clients", "clients"],
  ["Inspectors", "worker"],
];

const DOCUMENTS_LINKS = [
  ["Templates", "references", "/templates"],
  ["Affidavit", "document", "/create-documents/affidavit"],
  ["XRF", "document", "/create-documents/xrf"],
  ["XRF Friction", "document", "/create-documents/xrf-friction"],
  ["Dust Wipe", "document", "/create-documents/dust-wipe"],
  ["Paint Chips", "document", "/create-documents/paint-chips"],
];

const Links = () => {
  const currentPathname = useLocation().pathname;
  return (
    <div className="links">
      <div className="label">
        <h4>Navigation</h4>
      </div>

      <div className="links-container">
        {NAVIGATION_LINKS.map(([label, icon], idx) => {
          const path = "/" + label.toLowerCase().replace(" ", "-");
          return (
            <Link
              to={path}
              key={idx}
              className={`link ${currentPathname === path ? "active" : ""}`}
            >
              <Icon name={icon} /> &nbsp; {label}
            </Link>
          );
        })}
      </div>

      <div className="label">
        <h4>Create documents</h4>
      </div>

      <div className="links-container">
        {DOCUMENTS_LINKS.map(([label, icon, path], idx) => {
          return (
            <Link
              to={path}
              key={idx}
              className={`link ${currentPathname === path ? "active" : ""}`}
            >
              <Icon name={icon} /> &nbsp; {label}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Links;
