import "./Button.scss";
import React from "react";

const Button = ({ children, variant = "primary", ...props }) => {
  return (
    <button {...props} className={`button ${variant}`}>
      {children}
    </button>
  );
};

export default Button;
