export const GET_JOBS = `
    query jobs {
        jobs: allJobs(orderBy: STARTED_AT_DESC, first: 2) {
            nodes {
                startedAt
                completedAt
                status
                id
            }
        }
    }
`;

export const GET_BUILDINGS = `
    query getBuildings(
        $first: Int!
        $offset: Int!
        $orderBy: [BuildingsOrderBy!]
        $condition: BuildingCondition
        $filter: BuildingFilter
    ) {
        buildings: allBuildings(
            first: $first
            offset: $offset
            orderBy: $orderBy
            condition: $condition
            filter: $filter
        ) {
        nodes {
            id
            houseNumber
            borough
            postCode
            streetName
            stories
        }
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
            totalCount
        }
    }
`;

export const GET_VIOLATIONS = `
    query getViolations(
        $first: Int!
        $offset: Int!
        $orderBy: [ViolationsOrderBy!]
        $orderNumber: [String!]
    ) {
        violations: allViolations(
            first: $first
            offset: $offset
            orderBy: $orderBy
            filter: { orderNumber: { in: $orderNumber } }
        ) {
        nodes {
            id
            orderNumber
            createdAt
            buildingId
            building: buildingByBuildingId {
                houseNumber
                streetName
                borough
                postCode
            }
        }
        totalCount
        pageInfo {
            hasNextPage
            hasPreviousPage
        }
        }
    }  
`;

export const GET_CONTACTS = `
query getContacts($first: Int, $offset: Int!, $orderBy: [ContactsOrderBy!], $condition: ContactCondition, $filter: ContactFilter) {
    contacts: allContacts(
      first: $first
      offset: $offset
      orderBy: $orderBy,
      condition: $condition,
      filter: $filter,
    ) {
      nodes {
        id
        firstName
        lastName
        corporationName
        type
        houseNumber
        streetName
        apartment
        city
        state
        zip
        exportedCount
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_CONTACTS_CSV = `
query getContacts($first: Int, $offset: Int!, $orderBy: [ContactsOrderBy!], $condition: ContactCondition, $filter: ContactFilter) {
    contacts: allContacts(
      first: $first
      offset: $offset
      orderBy: $orderBy,
      condition: $condition,
      filter: $filter,
    ) {
      nodes {
        id
        firstName
        lastName
        corporationName
        houseNumber
        streetName
        apartment
        city
        state
        zip
        exportedCount
      }
    }
  }
`;

export const GET_VIOLATION = `
query getViolation($id: Int!) {
  violation: violationById(id: $id) {
    id
    orderNumber
    createdAt
    building: buildingByBuildingId {
      id
      houseNumber
      borough
      postCode
      registrationId
      streetName
    }
    registration: registrationByRegistrationId {
      contact: contactByContactId {
        id
        houseNumber
        firstName
        createdAt
        corporationName
        apartment
        city
        lastName
        state
        streetName
        type
        zip
      }
    }
  }
}
`;

export const GET_CONTACT = `
query getContact($id: Int!) {
  contact: contactById(id: $id) {
    id
    firstName
    lastName
    corporationName
    type
    houseNumber
    streetName
    apartment
    city
    state
    zip
    exportedCount
    registrations: registrationsByContactId {
      nodes {
        buildings: buildingsByRegistrationId {
          nodes {
            id
            borough
            houseNumber
            postCode
            registrationId
            stories
            streetName
            additionalInfo {
              units
              year
            }
          }
        }
      }
    }
  }
}
`;

export const UPDATE_EXPORTED_COUNT = `
  mutation updateExportedCount($ids: [Int!]) {
    setExportedCount(input: { contactIds: $ids }) {
      __typename
    }
  }
`;

export const GET_CLIENTS = `
query getClients($first: Int!, $offset: Int!, $filter: ClientFilter) {
  clients: allClients(first: $first, offset: $offset, orderBy: ID_DESC, filter: $filter) {
    nodes {
      id
      name
      city
      phoneNumber
      propertyCount
      email
      unitCount
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}`;

export const CREATE_CLIENT = `
mutation createClient(
  $name: String!
  $email: String!
  $propertyCount: String!
  $city: String!
  $phoneNumber: String!
  $unitCount: Int!
) {
  createClient(
    input: {
      client: {
        name: $name
        email: $email
        city: $city
        phoneNumber: $phoneNumber
        propertyCount: $propertyCount
        unitCount: $unitCount
      }
    }
  ) {
    client {
      id
    }
  }
}
`;

export const GET_CLIENT = `
query getClient($id: Int!) {
  client: clientById(id: $id) {
    id
    name
    city
    phoneNumber
    propertyCount
    email
    unitCount
  }
}`;

export const UPDATE_CLIENT = `
mutation updateClient(
  $id: Int!
  $name: String!
  $email: String!
  $propertyCount: String!
  $city: String!
  $phoneNumber: String!
  $unitCount: Int!
) {
  updateClientById(
    input: {
      clientPatch: {
        name: $name
        email: $email
        city: $city
        phoneNumber: $phoneNumber
        propertyCount: $propertyCount
        unitCount: $unitCount
      }
      id: $id
    }
  ) {
    client {
      id
      name
      city
      phoneNumber
      propertyCount
      email
      unitCount
    }
  }
}
`;

export const CREATE_PREMISE = `
mutation createPremise(
  $clientId: Int!
  $city: String!
  $address: String!
  $state: String!
  $zip: String!
  $bin: String
  $block: String
  $borough: String
  $lot: String
  $registrationId: String
  $corporationName: String
  $corporationAddress: String
  $corporationCity: String
  $corporationState: String
  $corporationZip: String
  $managerAddress: String
  $managerCity: String
  $managerEmail: String
  $managerName: String
  $managerPhone: String
  $managerZip: String
) {
  createPremises(
    input: {
      premises: {
        clientId: $clientId
        city: $city
        address: $address
        state: $state
        zip: $zip
        bin: $bin
        block: $block
        borough: $borough
        lot: $lot
        registrationId: $registrationId
        corporationName: $corporationName
        corporationAddress: $corporationAddress
        corporationCity: $corporationCity
        corporationState: $corporationState
        corporationZip: $corporationZip
        managerAddress: $managerAddress
        managerCity: $managerCity
        managerEmail: $managerEmail
        managerName: $managerName
        managerPhone: $managerPhone
        managerZip: $managerZip
      }
    }
  ) {
    premises {
      id
      state
      zip
      clientId
      city
      address
      bin
      block
      borough
      lot
      registrationId
      corporationName
      corporationAddress
      corporationCity
      corporationState
      corporationZip
      managerAddress
      managerCity
      managerEmail
      managerName
      managerPhone
      managerZip
    }
  }
}
`;

export const GET_PREMISES_BY_CLIENT = `
query getPremisesByClient($clientId: Int!) {
  premises: allPremises(
    orderBy: ID_DESC
    filter: { clientId: { equalTo: $clientId } }
  ) {
    nodes {
      id
      clientId
      state
      zip
      city
      address
      bin
      block
      borough
      lot
      registrationId
      corporationName
      corporationAddress
      corporationCity
      corporationState
      corporationZip
      managerAddress
      managerCity
      managerEmail
      managerName
      managerPhone
      managerZip
    }
  }
}
`;

export const GET_ALL_PREMISES = `
query getAllPremises {
  premises: allPremises(orderBy: ID_DESC) {
    nodes {
      id
      clientId
      state
      zip
      city
      address
      bin
      block
      borough
      lot
      registrationId
      corporationName
      corporationAddress
      corporationCity
      corporationState
      corporationZip
      client: clientByClientId {
        id
        name
        email
        phoneNumber
      }
      managerAddress
      managerCity
      managerEmail
      managerName
      managerPhone
      managerZip
    }
  }
}
`;

export const UPDATE_PREMISE = `
mutation updatePremise(
  $id: Int!
  $state: String!
  $zip: String!
  $city: String!
  $address: String!
  $bin: String
  $block: String
  $borough: String
  $lot: String
  $registrationId: String
  $corporationName: String
  $corporationAddress: String
  $corporationCity: String
  $corporationState: String
  $corporationZip: String
  $managerAddress: String
  $managerCity: String
  $managerEmail: String
  $managerName: String
  $managerPhone: String
  $managerZip: String
) {
  updatePremisesById(
    input: {
      premisesPatch: {
        state: $state
        zip: $zip
        city: $city
        address: $address
        bin: $bin
        block: $block
        borough: $borough
        lot: $lot
        registrationId: $registrationId
        corporationName: $corporationName
        corporationAddress: $corporationAddress
        corporationCity: $corporationCity
        corporationState: $corporationState
        corporationZip: $corporationZip
        managerAddress: $managerAddress
        managerCity: $managerCity
        managerEmail: $managerEmail
        managerName: $managerName
        managerPhone: $managerPhone
        managerZip: $managerZip
      }
      id: $id
    }
  ) {
    premises {
      id
      state
      zip
      city
      address
      bin
      block
      borough
      lot
      registrationId
      corporationName
      corporationAddress
      corporationCity
      corporationState
      corporationZip
      managerAddress
      managerCity
      managerEmail
      managerName
      managerPhone
      managerZip
    }
  }
}`;

export const GET_PROPERTY_AND_UNIT_COUNT = `
query clientCounts {
  allClients {
    nodes {
      propertyCount
      unitCount
    }
  }
}
`;

export const GET_ALL_INSPECTORS = `
query GET_ALL_INSPECTORS_QUERY {
  inspectors: allInspectors {
    nodes {
      id
      certNumber
      certExpiration
      instrumentNumber
      name
    }
    totalCount
  }
}
`;

export const CREATE_INSPECTOR = `
mutation createInspector(
  $name: String!
  $certNumber: String!
  $certExpiration: Date!
  $instrumentNumber: Int!
) {
  createInspector(
    input: {
      inspector: {
        name: $name
        certNumber: $certNumber
        certExpiration: $certExpiration
        instrumentNumber: $instrumentNumber
      }
    }
  ) {
    inspector {
      id
      instrumentNumber
      name
      certNumber
      certExpiration
    }
  }
}
`;

export const UPDATE_INSPECTOR = `
mutation updateInspector(
  $id: Int!
  $name: String
  $certNumber: String
  $certExpiration: Date
  $instrumentNumber: Int
) {
  updateInspectorById(
    input: {
      inspectorPatch: {
        name: $name
        certNumber: $certNumber
        certExpiration: $certExpiration
        instrumentNumber: $instrumentNumber
      }
      id: $id
    }
  ) {
    inspector {
      id
      instrumentNumber
      name
      certNumber
      certExpiration
    }
  }
}
`;

export const DELETE_INSPECTOR = `
  mutation deleteInspector($id: Int!) {
    deleteInspectorById(input: { id: $id }) {
      deletedInspectorId
    }
  }
`;
