import "./App.scss";
import React from "react";
import { SideNavigation } from "../components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  Buildings,
  Clients,
  Client,
  Violation,
  Violations,
  Contacts,
  Contact,
  CreateDocuments,
  Templates,
  Inspectors,
} from "../routes";
import qs from "qs";

const Unauthorized = () => (
  <div className="app">
    <div className="unauthorized">
      <h1>401 Unauthorized</h1>
    </div>
  </div>
);

const App = () => {
  const params = qs.parse(window.location?.search?.replaceAll("?", ""), {});

  if (!params.tkn && !window.localStorage.getItem("hpd-tkn")) {
    return <Unauthorized />;
  }

  const tkn = params.tkn || window.localStorage.getItem("hpd-tkn");

  if (tkn.length !== 64) {
    return <Unauthorized />;
  }

  window.localStorage.setItem("hpd-tkn", tkn);

  return (
    <div className="app">
      <Router basename="/admin">
        <SideNavigation />
        <main>
          <Switch>
            <Route path="/violation/:id" exact>
              <Violation />
            </Route>
            <Route path="/violations">
              <Violations />
            </Route>
            <Route path="/buildings">
              <Buildings />
            </Route>
            <Route path="/contacts">
              <Contacts />
            </Route>
            <Route path="/contact/:id" exact>
              <Contact />
            </Route>
            <Route path="/create-documents/:type">
              <CreateDocuments />
            </Route>
            <Route path="/clients">
              <Clients />
            </Route>
            <Route path="/client/:id" exact>
              <Client />
            </Route>
            <Route path="/templates">
              <Templates />
            </Route>
            <Route path="/inspectors">
              <Inspectors />
            </Route>
            <Redirect from="/" to="violations" />
          </Switch>
        </main>
      </Router>
    </div>
  );
};

export default App;
