import "./Client.scss";
import React, { useState } from "react";
import { useParams } from "react-router";
import { useClientStore } from "../../store/clients/hooks";
import { Table, Button, LoadingSpinner, Icon } from "../../components";
import { UpdateClient, CreatePremise, UpdatePremise } from "../../modals";
import { usePremisesStore } from "../../store/premises/hooks";

const Client = () => {
  const [showUpdateClient, toggleShowUpdateClient] = useState(false);

  const { id } = useParams();
  const { client } = useClientStore(id);

  if (client === undefined) {
    return (
      <div style={{ margin: "1rem" }}>
        <LoadingSpinner label="Loading client" />
      </div>
    );
  }

  if (client === null) {
    return <h1 color="white">Client not found</h1>;
  }

  const { name, phoneNumber, email, city, propertyCount, unitCount } = client;

  return (
    <>
      <div className="client">
        <div className="client-header">
          <h1>
            Client id: <strong>{id}</strong>
          </h1>
          <Button onClick={() => toggleShowUpdateClient(!showUpdateClient)}>
            <Icon name="pen" /> Edit client
          </Button>
        </div>
        <div className="client-body">
          <div className="pair">
            <div className="pair-name">Name</div>
            <div className="pair-value">{name}</div>
          </div>
          <div className="pair">
            <div className="pair-name">Phone</div>
            <div className="pair-value">{phoneNumber}</div>
          </div>
          <div className="pair">
            <div className="pair-name">Email</div>
            <div className="pair-value">{email}</div>
          </div>
          <div className="pair">
            <div className="pair-name">Borough</div>
            <div className="pair-value">{city}</div>
          </div>
          <div className="pair">
            <div className="pair-name"># of Properties</div>
            <div className="pair-value">{propertyCount}</div>
          </div>
          <div className="pair">
            <div className="pair-name"># of Units</div>
            <div className="pair-value">{unitCount}</div>
          </div>
        </div>
        <Premises clientId={id} />
      </div>
      {showUpdateClient && (
        <UpdateClient
          client={client}
          onClose={() => toggleShowUpdateClient(false)}
        />
      )}
    </>
  );
};

const Premises = ({ clientId }) => {
  const [showCreatePremise, toggleShowCreatePremise] = useState(false);
  const [showUpdatePremise, toggleShowUpdatePremise] = useState(null);

  const { fetching, premises } = usePremisesStore(clientId);

  if (fetching) {
    return (
      <div style={{ margin: "1rem" }}>
        <LoadingSpinner label="Loading associated premises" />
      </div>
    );
  }

  return (
    <>
      <div className="client-premises">
        <div className="client-premises-header">
          <h1>Premises</h1>
          <Button
            variant="flat"
            onClick={() => toggleShowCreatePremise(!showCreatePremise)}
          >
            <Icon name="plus" /> Create premise
          </Button>
        </div>
        <Table
          headers={[
            "Corporation",
            "Address",
            "City",
            "State",
            "Zip",
            "BIN",
            "Block",
            "Borough",
            "Lot",
            "Registration ID",
            "Actions",
          ]}
        >
          {premises.map((premise, idx) => (
            <Table.Row key={idx}>
              <Table.RowData>{premise.corporationName || "N/A"}</Table.RowData>
              <Table.RowData>{premise.address}</Table.RowData>
              <Table.RowData>{premise.city}</Table.RowData>
              <Table.RowData>{premise.state}</Table.RowData>
              <Table.RowData>{premise.zip}</Table.RowData>
              <Table.RowData>{premise.bin || "N/A"}</Table.RowData>
              <Table.RowData>{premise.block || "N/A"}</Table.RowData>
              <Table.RowData>{premise.borough || "N/A"}</Table.RowData>
              <Table.RowData>{premise.lot || "N/A"}</Table.RowData>
              <Table.RowData>{premise.registrationId || "N/A"}</Table.RowData>
              <Table.RowData>
                <Button
                  variant="flat"
                  onClick={() => toggleShowUpdatePremise(premise)}
                >
                  <Icon name="pen" /> Edit
                </Button>
              </Table.RowData>
            </Table.Row>
          ))}
        </Table>
      </div>
      {showCreatePremise && (
        <CreatePremise
          clientId={clientId}
          onClose={() => toggleShowCreatePremise(false)}
        />
      )}
      {showUpdatePremise && (
        <UpdatePremise
          premise={showUpdatePremise}
          onClose={() => toggleShowUpdatePremise(null)}
        />
      )}
    </>
  );
};

export default Client;
