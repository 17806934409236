import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { parseQueryString } from "../../utilities";
import { useViolationsStore } from "../../store/violations/hooks";
import { LoadingSpinner, Icon, Page, Select, Table } from "../../components";
import { formatDistance } from "date-fns";
import {
  SORTING_OPTIONS,
  RESULTS_LIMIT,
  ORDER_NUMBERS,
  DEFAULT_ORDER_NUMBERS,
} from "./constants";
import qs from "qs";

const Violations = () => {
  const history = useHistory();
  const [variables, updateVariables] = useState(
    parseQueryString(history.location?.search, {
      first: 100,
      offset: 0,
      orderBy: "CREATED_AT_DESC",
      orderNumber: DEFAULT_ORDER_NUMBERS,
    })
  );

  const { fetching, totalCount, violations, pageInfo } =
    useViolationsStore(variables);

  if (violations === undefined) {
    return (
      <div style={{ margin: "1rem" }}>
        <LoadingSpinner label="Loading violations" />
      </div>
    );
  }

  const onFilterChange = (event) => {
    const { name, value } = event.target;
    const vars = { ...variables, offset: 0 };

    if (name === "sortBy") {
      vars.orderBy = value;
    }

    if (name === "resultsPerPage") {
      vars.first = parseInt(value);
    }

    if (name === "filterByOrderNumber") {
      if (value === "") {
        vars.orderNumber = DEFAULT_ORDER_NUMBERS;
      } else {
        vars.orderNumber = [value];
      }
    }

    history.push({
      pathname: "/violations",
      search: qs.stringify(vars),
    });

    updateVariables(vars);
  };

  const onPreviousPage = () => {
    if (!pageInfo.hasPreviousPage) {
      return;
    }

    updateVariables({
      ...variables,
      offset: variables.offset - variables.first,
    });
  };

  const onNextPage = () => {
    if (!pageInfo.hasNextPage) {
      return;
    }

    updateVariables({
      ...variables,
      offset: variables.offset + variables.first,
    });
  };

  const filters = [
    <Select
      key="sortBy"
      name="sortBy"
      label="Sort by"
      options={SORTING_OPTIONS}
      onChange={onFilterChange}
      value={variables.orderBy}
    />,
    <Select
      key="resultsPerPage"
      name="resultsPerPage"
      label="Results per page"
      options={RESULTS_LIMIT}
      onChange={onFilterChange}
      value={variables.first.toString()}
    />,
    <Select
      key="filterByOrderNumber"
      name="filterByOrderNumber"
      label="Filter by order number"
      options={ORDER_NUMBERS}
      onChange={onFilterChange}
      value={
        variables.orderNumber.length === DEFAULT_ORDER_NUMBERS.length
          ? ""
          : variables.orderNumber[0]
      }
    />,
  ];

  const pageProps = {
    totalCount,
    filters,
    onPreviousPage,
    onNextPage,
  };

  const totalPageCount = Math.round(totalCount / variables.first);
  const currentPage = variables.offset / variables.first + 1;

  return (
    <Page
      {...pageProps}
      currentPage={currentPage}
      totalPageCount={totalPageCount}
      loading={fetching}
    >
      <Table headers={["ID", "Order Number", "Address", "Created", "Actions"]}>
        {violations.map((violation, idx) => {
          const { id, orderNumber, building = {}, createdAt } = violation;

          const { houseNumber, streetName, postCode, borough } = building || {};

          const address = `
            ${houseNumber} ${streetName}, ${borough} ${postCode ?? ""}`;

          const destinationQuery = `${houseNumber} ${streetName} ${borough} ${
            postCode ?? ""
          }`
            .replaceAll(" ", "+")
            .trim();

          return (
            <Table.Row key={idx}>
              <Table.RowData>
                <Link to={`/violation/${id}`} className="link" target="_blank">
                  {id}
                </Link>
              </Table.RowData>
              <Table.RowData>{orderNumber}</Table.RowData>
              <Table.RowData>{address}</Table.RowData>
              <Table.RowData>
                {formatDistance(new Date(`${createdAt}Z`), new Date(), {
                  addSuffix: true,
                })}
              </Table.RowData>
              <Table.RowData style={{ display: "flex", gap: "6px" }}>
                <a
                  href={`https://www.google.com/maps?saddr=My+Location&daddr=${destinationQuery}`}
                  target="_blank"
                  rel="noreferrer"
                  className="directions"
                >
                  <Icon name="directions" />
                  Get directions
                </a>
                <a
                  href={`https://www.google.com/search?q=${address.trim()}`}
                  target="_blank"
                  rel="noreferrer"
                  className="directions"
                >
                  <Icon name="search" />
                  Search
                </a>
              </Table.RowData>
            </Table.Row>
          );
        })}
      </Table>
    </Page>
  );
};

export default Violations;
