import { GRAPHQL_ENDPOINT } from "../constants";

const requestApi = async (query, variables = {}, additionalHeaders = {}) => {
  const headers = {
    accept: "application/json",
    "content-type": "application/json",
    authorization: `Bearer ${window.localStorage.getItem("hpd-tkn")}`,
    ...additionalHeaders,
  };

  const method = "POST";
  const body = JSON.stringify({ query, variables });

  const response = await fetch(GRAPHQL_ENDPOINT, {
    headers,
    method,
    body,
  });

  return await response.json();
};

export default requestApi;
