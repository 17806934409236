import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestApi } from "../../utilities";
import {
  CREATE_INSPECTOR,
  GET_ALL_INSPECTORS,
  UPDATE_INSPECTOR,
} from "../../queries";

const initialState = {
  inspectorsById: {},
  creatingInspector: false,
  updatingInspector: false,
  fetching: false,
  totalCount: 0,
};

export const fetchInspectors = createAsyncThunk(
  "inspectors/fetchInspectors",
  async () => {
    try {
      const { data } = await requestApi(GET_ALL_INSPECTORS);

      if (data.inspectors.nodes === null) {
        return null;
      }

      return {
        inspectors: data.inspectors.nodes,
        totalCount: data.inspectors.totalCount,
      };
    } catch {
      return null;
    }
  }
);

export const createInspector = createAsyncThunk(
  "inspectors/createInspector",
  async (input) => {
    try {
      const { data } = await requestApi(CREATE_INSPECTOR, input);

      if (data.createInspector.inspector === null) {
        return null;
      }

      return {
        id: data.createInspector.inspector.id,
      };
    } catch {
      return null;
    }
  }
);

export const fetchInspector = createAsyncThunk(
  "inspectors/fetchInspector",
  async (id) => {
    try {
      const { data } = await requestApi(GET_ALL_INSPECTORS, {
        id: parseInt(id),
      });

      return {
        id,
        inspector: data.inspector,
      };
    } catch {
      return {
        id,
        inspector: null,
      };
    }
  }
);

export const updateInspector = createAsyncThunk(
  "inspectors/updateInspector",
  async (input) => {
    try {
      const { data } = await requestApi(UPDATE_INSPECTOR, input);

      if (data.updateInspectorById.inspector === null) {
        return null;
      }

      return data.updateInspectorById.inspector;
    } catch {
      return null;
    }
  }
);

const { reducer } = createSlice({
  name: "inspectors",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchInspectors.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(fetchInspectors.fulfilled, (state, action) => {
      state.inspectors = action.payload?.inspectors || null;
      state.totalCount = action.payload?.totalCount ?? 0;
      state.fetching = false;
    });

    builder.addCase(createInspector.pending, (state) => {
      state.creatingInspector = true;
    });

    builder.addCase(createInspector.fulfilled, (state) => {
      state.creatingInspector = false;
    });

    builder.addCase(fetchInspector.fulfilled, (state, action) => {
      state.inspectorsById[action.payload.id] = action.payload.inspector;
    });

    builder.addCase(updateInspector.pending, (state) => {
      state.updatingInspector = true;
    });

    builder.addCase(updateInspector.fulfilled, (state, action) => {
      state.updatingInspector = false;
      if (!action.payload) {
        return;
      }

      state.inspectorsById[action.payload.id] = action.payload;
    });
  },
});

export default reducer;
