import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestApi } from "../../utilities";
import {
  CREATE_PREMISE,
  GET_ALL_PREMISES,
  GET_PREMISES_BY_CLIENT,
  UPDATE_PREMISE,
} from "../../queries";

const initialState = {
  premiseById: {},
  premisesByClient: {},
  creatingPremise: false,
  updatingPremise: false,
  fetchingAllPremises: false,
};

export const createPremise = createAsyncThunk(
  "premises/createPremise",
  async (input) => {
    try {
      const { data } = await requestApi(CREATE_PREMISE, input);

      return data.createPremises.premises;
    } catch {
      return null;
    }
  }
);

export const fetchPremisesByClient = createAsyncThunk(
  "premises/fetchPremisesByClient",
  async (clientId) => {
    try {
      const { data } = await requestApi(GET_PREMISES_BY_CLIENT, {
        clientId: parseInt(clientId),
      });

      return data.premises.nodes;
    } catch {
      return null;
    }
  }
);

export const fetchAllPremises = createAsyncThunk(
  "premises/fetchAllPremises",
  async () => {
    try {
      const { data } = await requestApi(GET_ALL_PREMISES);

      return data.premises.nodes;
    } catch {
      return null;
    }
  }
);

export const updatePremise = createAsyncThunk(
  "premises/updatePremise",
  async (input) => {
    try {
      const { data } = await requestApi(UPDATE_PREMISE, input);

      return data.updatePremisesById.premises;
    } catch {
      return null;
    }
  }
);

const { reducer } = createSlice({
  name: "premises",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createPremise.pending, (state) => {
      state.creatingPremise = true;
    });

    builder.addCase(createPremise.fulfilled, (state, action) => {
      state.creatingPremise = false;
      if (!action.payload) {
        return;
      }

      const { clientId } = action.meta.arg;

      state.premisesByClient[clientId].unshift(action.payload.id);
      state.premiseById[action.payload.id] = action.payload;
    });

    builder.addCase(fetchPremisesByClient.fulfilled, (state, action) => {
      const clientId = action.meta.arg;

      if (!action.payload || action.payload?.length === 0) {
        state.premisesByClient[clientId] = [];
        return;
      }

      action.payload?.forEach((premise) => {
        if (!state.premisesByClient[clientId]) {
          state.premisesByClient[clientId] = [];
        }

        state.premiseById[premise.id] = premise;
        state.premisesByClient[clientId].push(premise.id);
      });
    });

    builder.addCase(updatePremise.pending, (state, action) => {
      state.updatingPremise = true;
    });

    builder.addCase(updatePremise.fulfilled, (state, action) => {
      state.updatingPremise = false;
      if (!action.payload) {
        return;
      }

      state.premiseById[action.payload.id] = action.payload;
    });

    builder.addCase(fetchAllPremises.pending, (state) => {
      state.fetchingAllPremises = true;
    });

    builder.addCase(fetchAllPremises.fulfilled, (state, action) => {
      state.fetchingAllPremises = false;
      if (!action.payload) {
        return;
      }
      const premises = action.payload;

      premises.forEach((premise) => {
        state.premiseById[premise.id] = premise;
      });
    });
  },
});

export default reducer;
