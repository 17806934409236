import { configureStore } from "@reduxjs/toolkit";
import buildings from "./buildings/reducer";
import jobs from "./jobs/reducer";
import search from "./search/reducer";
import violations from "./violations/reducer";
import contacts from "./contacts/reducer";
import inspectors from "./inspectors/reducer";
import clients from "./clients/reducer";
import premises from "./premises/reducer";
import templates from "./templates/reducer";

const store = configureStore({
  reducer: {
    buildings,
    jobs,
    search,
    violations,
    contacts,
    inspectors,
    clients,
    premises,
    templates,
  },
});

export default store;
