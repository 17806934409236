export const RESULTS_LIMIT = [
  {
    value: "100",
  },
  {
    value: "200",
  },
  {
    value: "500",
  },
  {
    value: "1000",
  },
];

export const TYPES = [
  {
    value: "",
    label: "Show All",
  },
  {
    value: "Agent",
  },
  {
    value: "CorporateOwner",
  },
];

export const SORTING_OPTIONS = [
  {
    label: "Natural",
    value: "NATURAL",
  },
  {
    label: "ID: Smallest to largest",
    value: "ID_ASC",
  },
  {
    label: "ID: Largest to smallest",
    value: "ID_DESC",
  },
  {
    label: "Corporation Name: A to Z",
    value: "CORPORATION_NAME_ASC",
  },
  {
    label: "Corporation Name: Z to A",
    value: "CORPORATION_NAME_DESC",
  },
  {
    label: "First Name: A to Z",
    value: "FIRST_NAME_ASC",
  },
  {
    label: "First Name: Z to A",
    value: "FIRST_NAME_DESC",
  },
  {
    label: "Last Name: A to Z",
    value: "LAST_NAME_ASC",
  },
  {
    label: "Last Name: Z to A",
    value: "LAST_NAME_DESC",
  },
  {
    label: "House Number: Smallest to largest",
    value: "HOUSE_NUMBER_ASC",
  },
  {
    label: "House Number: Largest to smallest",
    value: "HOUSE_NUMBER_DESC",
  },
  {
    label: "Street Name: A to Z",
    value: "STREET_NAME_ASC",
  },
  {
    label: "Street Name: Z to A",
    value: "STREET_NAME_DESC",
  },
  {
    label: "Apartment: A to Z",
    value: "APARTMENT_ASC",
  },
  {
    label: "Apartment: Z to A",
    value: "APARTMENT_DESC",
  },
  {
    label: "City: A to Z",
    value: "CITY_ASC",
  },
  {
    label: "City: Z to A",
    value: "CITY_DESC",
  },
  {
    label: "State: A to Z",
    value: "STATE_ASC",
  },
  {
    label: "State: Z to A",
    value: "STATE_DESC",
  },
  {
    label: "Zip code: Smallest to largest",
    value: "ZIP_ASC",
  },
  {
    label: "Zip code: Largest to smallest",
    value: "ZIP_DESC",
  },
];

export const STORIES_OPTIONS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "2+",
    value: 2,
  },
  {
    label: "3+",
    value: 3,
  },
  {
    label: "4+",
    value: 4,
  },
  {
    label: "5+",
    value: 5,
  },
  {
    label: "6+",
    value: 6,
  },
  {
    label: "7+",
    value: 7,
  },
  {
    label: "8+",
    value: 8,
  },
  {
    label: "9+",
    value: 9,
  },
  {
    label: "10+",
    value: 10,
  },
];
