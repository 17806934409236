import "./Field.scss";
import React, { forwardRef } from "react";

const Field = forwardRef((props, ref) => (
  <div className="field">
    <input {...props} ref={ref} />
  </div>
));

export default Field;
