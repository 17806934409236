import { REST_ENDPOINT } from "../constants";

const requestRestApi = async (
  path,
  body = {},
  method = "GET",
  additionalHeaders = {}
) => {
  const headers = {
    authorization: `Bearer ${window.localStorage.getItem("hpd-tkn")}`,
    ...additionalHeaders,
  };

  const init = {
    headers,
    method,
  };

  if (method === "POST") {
    init.body = body;
  }

  const response = await fetch(REST_ENDPOINT + "/api" + path, init);

  return await response.json();
};

export default requestRestApi;
