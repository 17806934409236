import "./CreateDocuments.scss";
import React, { useState } from "react";
import { AffidavitForm } from "./AffidavitForm";
import { XRFForm } from "./XRFForm";
import { XRFFrictionForm } from "./XRFFrictionForm";
import { DustWipeForm } from "./DustWipeForm";
import { PaintChipsForm } from "./PaintChipsForm";
import { LoadingSpinner } from "../../components";
import { REST_ENDPOINT } from "../../constants";
import CreatedDocumentsList from "./CreatedDocumentsList";
import { useParams } from "react-router-dom";

const formByType = {
  xrf: XRFForm,
  "xrf-friction": XRFFrictionForm,
  "dust-wipe": DustWipeForm,
  "paint-chips": PaintChipsForm,
  affidavit: AffidavitForm,
};

const CreateDocuments = () => {
  const [creating, setCreating] = useState(false);
  const [files, setFiles] = useState([]);
  const { type } = useParams();

  const onCreate = async (payload) => {
    setCreating(true);

    const raw = await fetch(REST_ENDPOINT + "/api/create_documents", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${window.localStorage.getItem("hpd-tkn")}`,
      },
      body: JSON.stringify(payload),
    });
    const response = await raw.json();

    const files = [
      {
        filename: response.document,
        link: REST_ENDPOINT + "/files/" + response.document,
      },
    ];

    setFiles(files);
    setCreating(false);
  };

  const onCloseModal = () => {
    setFiles([]);
  };

  if (creating) {
    return (
      <div className="create-documents">
        <div className="create-documents-body center">
          <LoadingSpinner label="Creating documents.." />
        </div>
      </div>
    );
  }

  const Form = formByType[type];

  return (
    <div className="create-documents">
      <CreatedDocumentsList files={files} onCloseModal={onCloseModal} />
      <div className="create-documents-body">
        <Form onCreate={onCreate} />
      </div>
    </div>
  );
};

export default CreateDocuments;
