import "./Search.scss";
import React, { useCallback, useRef } from "react";
import { Field, Icon } from "../";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setString } from "../../store/search/reducer";

const getPlaceholder = (pathname) => {
  if (pathname?.includes("buildings")) {
    return "Search buildings..";
  }

  if (pathname?.includes("contacts")) {
    return "Search contacts..";
  }

  return null;
};

const Search = () => {
  const dispatch = useDispatch();

  const currentPathname = useLocation().pathname;
  const inputRef = useRef(null);
  const placeholder = getPlaceholder(currentPathname);
  const disabled = placeholder === null;

  const onSubmit = useCallback(() => {
    const value = inputRef?.current?.value || null;
    dispatch(setString(value));
  }, [dispatch]);

  const onKeyDown = useCallback(
    (event) => {
      // enter
      if (event.keyCode === 13) {
        onSubmit();
      }
    },
    [onSubmit]
  );

  return (
    <div className={`search ${disabled ? "disabled" : ""}`}>
      <div className="label">
        <h4>Search</h4>
      </div>
      <div className="top-search-field-container">
        <Field
          onKeyDown={onKeyDown}
          placeholder={placeholder || "Start typing.."}
          disabled={disabled}
          ref={inputRef}
        />
        <button disabled={disabled} onClick={onSubmit}>
          <Icon name="search" />
        </button>
      </div>
    </div>
  );
};

export default Search;
