import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInspectors } from "./reducer";

export const useInspectorsStore = () => {
  const dispatch = useDispatch();
  const fetching = useSelector((state) => state.inspectors.fetching);
  const inspectors = useSelector((state) => state.inspectors.inspectors);
  const totalCount = useSelector((state) => state.inspectors.totalCount);
  const pageInfo = useSelector((state) => state.inspectors.pageInfo);

  useEffect(() => {
    dispatch(fetchInspectors());
  }, [dispatch]);

  return {
    fetching,
    inspectors,
    totalCount,
    pageInfo,
  };
};
