import "./CreatePremise.scss";
import React, { useState } from "react";
import { Button, Icon, Modal } from "../components";
import { useDispatch } from "react-redux";
import { createPremise } from "../store/premises/reducer";

export const CORPORATION_FIELDS = [
  ["corporationName", "text", "Corporation Name"],
  ["corporationAddress", "text", "Registered Address"],
  ["corporationCity", "text", "City"],
  ["corporationState", "text", "State"],
  ["corporationZip", "text", "Zip"],
];

export const MANAGER_FIELDS = [
  ["managerName", "text", "Name"],
  ["managerAddress", "text", "Address"],
  ["managerCity", "text", "City"],
  ["managerZip", "text", "Zip"],
  ["managerEmail", "email", "Email"],
  ["managerPhone", "text", "Phone"],
];

export const PREMISE_FIELDS = [
  ["address", "text", "Address"],
  ["city", "text", "City"],
  ["state", "text", "State"],
  ["zip", "text", "Zip"],
  ["registrationId", "text", "Registration ID"],
  ["bin", "text", "Bin"],
  ["block", "text", "Block"],
  ["lot", "text", "Lot"],
  ["borough", "text", "Borough"],
];

const initialState = [...PREMISE_FIELDS, ...CORPORATION_FIELDS].reduce(
  (acc, [name]) => {
    acc[name] = "";

    return acc;
  },
  {}
);

const CreatePremise = ({ clientId, onClose }) => {
  const dispatch = useDispatch();
  //   const history = useHistory();

  const [state, updateState] = useState({
    ...initialState,
    clientId: parseInt(clientId),
  });

  const onChange = (event) => {
    updateState((currState) => ({
      ...currState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = async () => {
    if (Object.keys(state).some((key) => state[key] === "")) {
      return;
    }

    const response = await dispatch(createPremise(state));

    if (!response.payload) {
      alert("Something went wrong");
      return;
    }

    onClose();
  };

  return (
    <Modal
      title="Create premise"
      onClose={onClose}
      footer={
        <Button onClick={onSubmit}>
          <Icon name="plus" />
          Create
        </Button>
      }
    >
      <div className="update-premise">
        <div className="update-premise-fields-group">
          <h1>Corporation</h1>
          {CORPORATION_FIELDS.map((currField, idx) => {
            const [name, type, placeholder] = currField;
            return (
              <div key={idx} className="field">
                <label htmlFor={name}>{placeholder}</label>
                <input
                  value={state[name]}
                  name={name}
                  type={type}
                  onChange={onChange}
                />
              </div>
            );
          })}
        </div>
        <div className="update-premise-fields-group">
          <h1>Manager</h1>
          {MANAGER_FIELDS.map((currField, idx) => {
            const [name, type, placeholder] = currField;
            return (
              <div key={idx} className="field">
                <label htmlFor={name}>{placeholder}</label>
                <input
                  value={state[name]}
                  name={name}
                  type={type}
                  onChange={onChange}
                />
              </div>
            );
          })}
        </div>
        <div className="update-premise-fields-group">
          <h1>Premise</h1>
          {PREMISE_FIELDS.map((currField, idx) => {
            const [name, type, placeholder] = currField;
            return (
              <div key={idx} className="field">
                <label htmlFor={name}>{placeholder}</label>
                <input
                  value={state[name]}
                  name={name}
                  type={type}
                  onChange={onChange}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default CreatePremise;
