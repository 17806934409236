import "./CreateClient.scss";
import React, { useState } from "react";
import { Button, Icon, Modal } from "../components";
import { useDispatch } from "react-redux";
import { createClient } from "../store/clients/reducer";
import { useHistory } from "react-router-dom";

const FIELDS = [
  ["name", "text", "Client name"],
  ["phoneNumber", "text", "Phone"],
  ["email", "text", "Email"],
  ["city", "text", "Borough"],
  ["propertyCount", "text", "# of Properties"],
  ["unitCount", "number", "# of Units"],
];

const initialState = FIELDS.reduce((acc, [name]) => {
  acc[name] = "";

  return acc;
}, {});

const CreateClient = ({ onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, updateState] = useState(initialState);

  const onChange = (event) => {
    updateState((currState) => ({
      ...currState,
      [event.target.name]:
        event.target.name === "unitCount"
          ? parseInt(event.target.value)
          : event.target.value,
    }));
  };

  const onSubmit = async () => {
    if (Object.keys(state).some((key) => state[key] === "")) {
      return;
    }

    const response = await dispatch(createClient(state));

    if (!response.payload) {
      alert("Something went wrong");
      return;
    }

    onClose();

    history.push(`/client/${response.payload.id}`);
  };

  return (
    <Modal
      title="Create client"
      onClose={onClose}
      footer={
        <Button onClick={onSubmit}>
          <Icon name="plus" />
          Create
        </Button>
      }
    >
      <div className="create-client">
        <div>
          {FIELDS.map((currField, idx) => {
            const [name, type, placeholder] = currField;
            return (
              <div key={idx} className="field">
                <label htmlFor={name}>{placeholder}</label>
                <input
                  value={state[name]}
                  name={name}
                  type={type}
                  onChange={onChange}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default CreateClient;
