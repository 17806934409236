export const BOROUGH_FILTERS = [
  {
    value: "",
    label: "Show All",
  },
  {
    value: "BRONX",
    label: "Bronx",
  },
  {
    value: "BROOKLYN",
    label: "Brooklyn",
  },
  {
    value: "MANHATTAN",
    label: "Manhattan",
  },
  {
    value: "QUEENS",
    label: "Queens",
  },
  {
    value: "STATEN ISLAND",
    label: "Staten Island",
  },
];

export const SORTING_OPTIONS = [
  {
    value: "NATURAL",
    label: "Normal",
  },
  {
    value: "HOUSE_NUMBER_ASC",
    label: "House Number: Smallest to largest",
  },
  {
    value: "HOUSE_NUMBER_DESC",
    label: "House Number: Largest to smallest",
  },
  {
    value: "STREET_NAME_ASC",
    label: "Street Name: A to Z",
  },
  {
    value: "STREET_NAME_DESC",
    label: "Street Name: Z to A",
  },
  {
    value: "POST_CODE_ASC",
    label: "Post Code: Smallest to largest",
  },
  {
    value: "POST_CODE_DESC",
    label: "Post Code: Largest to smallest",
  },
  {
    value: "BOROUGH_ASC",
    label: "Borough: A to Z",
  },
  {
    value: "BOROUGH_DESC",
    label: "Borough: Z to A",
  },
];

export const RESULTS_LIMIT = [
  {
    value: "100",
  },
  {
    value: "200",
  },
  {
    value: "500",
  },
  {
    value: "1000",
  },
];
