import "./SideNavigation.scss";
import React from "react";
import Top from "./Top";
import Links from "./Links";
import RecentJobs from "./RecentJobs";
import Search from "./Search";

const SideNavigation = () => {
  return (
    <div className="side-navigation">
      <Top />
      <Search />
      <Links />
      <RecentJobs />
    </div>
  );
};

export default SideNavigation;
