import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs } from "./reducer";

export const useJobsStore = () => {
  const dispatch = useDispatch();
  const fetching = useSelector((state) => state.jobs.fetching);
  const jobs = useSelector((state) => state.jobs.jobs);

  useEffect(() => {
    if (!fetching && jobs === undefined) {
      dispatch(fetchJobs());
    }
  }, [dispatch, fetching, jobs]);

  return {
    jobs,
  };
};
