import "./Inspectors.scss";
import React, { useState, useMemo, useCallback } from "react";
import { LoadingSpinner, Page, Table, Button, Icon } from "../../components";
import { useInspectorsStore } from "../../store/inspectors/hooks";
import { CreateInspector, UpdateInspector } from "../../modals";

const isCertificateExpirated = (expiryDate) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to midnight for comparison

  const checkDate = new Date(expiryDate);
  checkDate.setHours(0, 0, 0, 0); // Set time to midnight for comparison

  return checkDate.getTime() < today.getTime();
};

const Inspectors = () => {
  const {
    fetching,
    totalCount,
    inspectors: rawInspectors,
  } = useInspectorsStore();
  const [showCreateInspector, toggleShowCreateInspector] = useState(false);
  const [showEditInspector, toggleShowEditInspector] = useState(null);

  const inspectors = useMemo(() => {
    if (!rawInspectors) {
      return rawInspectors;
    }

    return rawInspectors.map(({ certExpiration, ...inspector }) => {
      const certificateExpiration = new Date(certExpiration);
      const certExpired = isCertificateExpirated(certExpiration);
      return {
        ...inspector,
        certExpiration: certificateExpiration.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        certExpired,
      };
    });
  }, [rawInspectors]);

  const onEditInspector = useCallback(
    (idx) => {
      toggleShowEditInspector(rawInspectors[idx]);
    },
    [rawInspectors, toggleShowEditInspector]
  );

  if (fetching) {
    return (
      <div style={{ margin: "1rem" }}>
        <LoadingSpinner label="Loading.." />
      </div>
    );
  }

  return (
    <>
      <Page
        totalCount={totalCount}
        filters={[
          <Button
            key="add-inspector"
            onClick={() => toggleShowCreateInspector(true)}
          >
            <Icon name="plus" />
            Add inspector
          </Button>,
        ]}
        currentPage={1}
        totalPageCount={0}
      >
        <Table
          headers={[
            "ID",
            "name",
            "Certificate #",
            "Certificate Expiration",
            "Gun #",
            "Actions",
          ]}
        >
          {inspectors?.map((inspector, idx) => {
            const {
              id,
              name,
              certNumber,
              certExpiration,
              certExpired,
              instrumentNumber,
            } = inspector;

            return (
              <Table.Row key={idx}>
                <Table.RowData>{id}</Table.RowData>
                <Table.RowData>{name}</Table.RowData>
                <Table.RowData>{certNumber}</Table.RowData>
                <Table.RowData
                  style={certExpired ? { color: "indianred" } : {}}
                >
                  {certExpiration}
                </Table.RowData>
                <Table.RowData>{instrumentNumber}</Table.RowData>
                <Table.RowData>
                  <Button variant="flat" onClick={() => onEditInspector(idx)}>
                    Edit
                  </Button>
                </Table.RowData>
              </Table.Row>
            );
          })}
        </Table>
      </Page>
      {showCreateInspector && (
        <CreateInspector onClose={() => toggleShowCreateInspector(false)} />
      )}

      {showEditInspector && (
        <UpdateInspector
          inspector={showEditInspector}
          onClose={() => toggleShowEditInspector(null)}
        />
      )}
    </>
  );
};

export default Inspectors;
